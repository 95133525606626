import { Flex } from "antd";
import ArrowBack from "src/components/common/arrowback-button";
import ProfileButton from "src/components/common/profile-button";
import { Tabs, Spin } from "antd";
import type { TabsProps } from "antd";
import "./styles.scss";
import QuizBar from "./components/quiz-bar";
import { userProgressLangData } from "./langData";
import { useTypedSelector } from "src/app/store";
import { useGetQuizesMutation } from "src/app/services/insights";
import { useEffect, useState } from "react";
import { IQuizeRes } from "src/app/services/insights/type";

const onChange = (key: string) => {
  // console.log(key);
};

export default function UserProfile() {
  const [data, setData] = useState<IQuizeRes[]>([]); // Update the type of 'data' state variable
  const [getQuizes, { isLoading }] = useGetQuizesMutation();
  useEffect(() => {
    getQuizes()
      .unwrap()
      .then((res) => {
        setData(res.results);
      });
  }, []);
  const currLang = useTypedSelector((state) => state.language);
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: userProgressLangData[currLang].New,
    },
    {
      key: "2",
      label: userProgressLangData[currLang].unFinished,
    },
    {
      key: "3",
      label: userProgressLangData[currLang].all,
    },
  ];
  return (
    <div className="user-progress">
      <div className="container">
        <br />
        <Flex align="center" justify="space-between">
          <ArrowBack navigateTo="/" />
          <h3>{userProgressLangData[currLang].dailyProgress}</h3>
          <ProfileButton size={32} />
        </Flex>
        <br />
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        <Flex gap={10} vertical>
          {data.length > 0 ? (
            data.map((item) => (
              <QuizBar
                key={item.id}
                quizName={item.title[currLang] || ""}
                navigateTo={`/quiz/${item.id}`}
              />
            ))
          ) : isLoading ? (
            <Spin className="loading-quiz" tip="Loading...">
              <div></div>
            </Spin>
          ) : (
            <div className="no-quiz-container">
              <h3>{userProgressLangData[currLang].noQuiz}</h3>
            </div>
          )}
        </Flex>
      </div>
    </div>
  );
}
