import { api } from "../api";
import { IAcceptTermsOfUsage, IProfileRes } from "./type";

export const profileApi = api.injectEndpoints({
  endpoints: (build) => ({
    //Get profile endpoint
    getProfile: build.mutation<IProfileRes, void>({
      query: () => ({ url: "/users/my-profile" }),
    }),
    acceptTermsOfUsage: build.mutation<IProfileRes, void>({
      query: (body) => ({
        url: "/users/my-profile",
        method: "PATCH",
        body: {
          accepted_terms_of_usage: true,
        },
      }),
    }),
  }),
});

export const { useGetProfileMutation, useAcceptTermsOfUsageMutation } =
  profileApi;
