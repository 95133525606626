import { api } from "../api";
import {
  IverifyToken,
  IverifyTokenRes,
  IloginWithTelegram,
  IloginWithTelegramRes,
  IverifyPhoneNumber,
  ICreatePassword,
  ICreatePasswordRes,
  IverifyPhoneNumberRes,
  ISignInRes,
  ISignIn,
} from "./type";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    //Validation endpoint
    verifyToken: build.mutation<IverifyTokenRes, IverifyToken>({
      query: (body) => ({
        url: "users/validate-email",
        method: "POST",
        body,
      }),
    }),
    loginWithTelegram: build.mutation<
      IloginWithTelegramRes,
      IloginWithTelegram
    >({
      query: (body) => ({
        url: "users/sign-up",
        method: "POST",
        body,
      }),
    }),
    verifyPhoneNumber: build.mutation<
      IverifyPhoneNumberRes,
      IverifyPhoneNumber
    >({
      query: (body) => ({
        url: "users/phone-verify",
        method: "POST",
        body,
      }),
    }),
    createPassword: build.mutation<ICreatePasswordRes, ICreatePassword>({
      query: (body) => ({
        url: "users/set-password",
        method: "POST",
        body,
      }),
    }),
    signIn: build.mutation<ICreatePasswordRes, ISignIn>({
      query: (body) => ({
        url: "users/sign-in",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useVerifyTokenMutation,
  useLoginWithTelegramMutation,
  useVerifyPhoneNumberMutation,
  useCreatePasswordMutation,
  useSignInMutation,
} = authApi;
