export const afertaLangData = {
  uz: `<h2>Atamalar va ta’riflar</h2>
Ushbu Ofertada, agar kontekst boshqacha talab qilmasa, quyidagi atamalar quyidagi ma'nolarga ega va uning ajralmas qismi hisoblanadi:
«Administrator» - O’zbekiston Respublikasi, Toshkent sh., Mirzo-Ulugbek tumani, Olamchi MFY, Turtarik ko’chasi, 3-5 uy manzilida joylashgan, O‘zbekiston Respublikasi qonun hujjatlariga muvofiq tashkil etilgan va tadbirkorlik faoliyatini amalga oshiruvchi, respondentlar oʻrtasida soʻrovnomalar oʻtkazish boʻyicha tadbirlar tashkilotchisi, shuningdek, Telegram bot va soʻrovnomalardan oʻtish maqsadida ishlab chiqilgan boshqa resurslarni boshqaruvchi dasturchi bo’lgan yuridik shaxs, " INSIGHT DATA " Ma’suliyati cheklangan jamiyati (STIR 310 439 959).
“Respondent” – 18 yoshga to‘lgan, ijtimoiy yoki boshqa turdagi so‘rovnomada ishtirok etuvchi, Ofertada ko‘rsatilgan harakatlarni amalga oshirish orqali ushbu Ofertani qabul qiluvchi jismoniy shaxs.
Xizmat/Telegram bot/veb-sayt – INSIGHT DATA MChJ tomonidan ishlab chiqilgan va boshqariluvchi, so’rovnomada qatnashish uchun jismoniy shaxslar kirishini ta'minlashga qaratilgan @ insight_web_bot dasturiy ta'minot/ilova/veb-sayti.
"Foydalanuvchi shartnomasi" - umumiy ma'noda Administrator va Foydalanuvchi o'rtasidagi kelishuv. Ushbu Oferta doirasida Foydalanuvchi shartnomasi - Administrator va Respondent o'rtasida Administrator Xizmatlaridan foydalanish shartlari bo'yicha kelishuv.
«So’rovnoma» - tadqiqot predmetini tashkil etuvchi ma'lum fikrlar, bilimlar va ijtimoiy faktlar to'g'risida ma'lumot to'plash va olishdan iborat sotsiologik tadqiqot usuli.
"Shartli ballar/ballar" - So'rovnomani to'ldirganligi yoki Administrator Xizmatlarida u yoki bu harakatni amalga oshirgani uchun Respondentga mukofot sifatida Xizmat qiluvchi va hisoblangan shartli valyuta; ularni so'mga almashtirish yoki Administrator hamkorlarining sodiqlik dasturlarida sarflash mumkin.
Ushbu Ofertaning maqsadlari uchun birlikdagi atamalar va ta'riflar ko'plikdagi atamalar va ta'riflarga ham tegishli va aksincha.
</br></br>
<h2>1. Umumiy qoidalar</h2>
1.1. O‘zbekiston Respublikasi Fuqarolik kodeksining 369-moddasiga muvofiq, mazkur hujjat jismoniy shaxslarga yo‘naltirilgan ommaviy Oferta hisoblanadi va quyida keltirilgan shartlarni qabul qilib, muayyan harakatlarni amalga oshirgan taqdirda, jismoniy shaxs (Respondent) Administratorning ushbu Ofertasini qabul qiladi, va bu Shartnoma tuzishga tengdir.
</br></br>
1.2. Administrator va Respondent zarur huquqiy va aqliy layoqatga, shuningdek, ushbu Ofertani tuzish va bajarish uchun zarur va yetarli bo'lgan barcha huquq va vakolatlarga ega ekanligiga kafolat beradi.
</br></br>
1.3. Ushbu Ofertaning predmeti - Administrator tomonidan ushbu Ofertada ko'rsatilgan shartlar asosida Respondentlarga So'rovnomalar o'tkazish bo'yicha o'z Xizmatlarini taklif qilish (Telegram bot, veb-sayt, mobil ilova va boshqalar). Administrator ushbu Ofertani istalgan vaqtda hech qanday maxsus ogohlantirishsiz o'zgartirish huquqini o'zida saqlab qoladi. Agar Respondent Ofertadagi o'zgarishlar e'lon qilinganidan keyin Administrator Xizmatlaridan foydalanishda davom etsa, Respondent shu bilan Oferta shartlariga kiritilgan o'zgartirishlarni qabul qilgan deb hisoblanadi.
</br></br>
1.4. Ushbu Oferta bilan Administrator cheklanmagan miqdordagi odamlarni @insight_web_bot Telegram boti va Administratorning boshqa Xizmatlari orqali onlayn So‘rovnomalarda ishtirok etishga taklif qiladi. Ushbu Xizmatlar insight.uz veb-interfeysi va ixtisoslashtirilgan apparat-dasturiy ta'minot vositalaridan foydalangan holda Respondentlar tomonidan kiritilgan ma'lumotlarni uzatish, to'plash va qayta ishlashni, shuningdek Respondentlar vaqti-vaqti bilan ishtirok etadigan So'rovnomalar natijalarini elektron shaklda taqdim etishni ta'minlaydi. Respondentlarga So'rovnomalarda qatnashish uchun shartli ball/ballar beriladi. Administrator o'z xohishiga ko'ra istalgan vaqtda taqdim etilayotgan Xizmatlarning bir qismini yoki barchasini qo'shish, bekor qilish yoki o'zgartirish huquqini o'zida saqlab qoladi.
</br></br>
1.5. So'rovnomalar ham ommaviy, ham tor qamrovga ega bo'lishi mumkin. Har bir savol shartli ball bilan taqdirlanadi, ular butun So'rovnoma yakunlangandan so'ng beriladi. So’rovnomani to'ldirishda va ro'yxatdan o'tish paytida talab qilinadigan boshqa tadbirlarda Administrator har bir savolning narxini o'z xohishiga ko'ra belgilashga haqli.
</br></br>
1.5.2. So'rovnomaning tugash vaqti So'rovnomada berilgan barcha savollarga javoblarni olish vaqti hisoblanadi. Agar So'rov muddati belgilansa va bu muddat Respondent barcha savollarga javob bergunga qadar tugasa, bu So'rovnoma haqiqiy emas deb topiladi va buning uchun shartli ballar berilmaydi.
</br></br>
1.5.3. Muayyan ko'rsatkichlarga erishilgandan so'ng (muddat tugashi, kerakli ma'lumotlarni to'plash, berilgan barcha savollarga javoblarni olish va boshqalar) So'rovnoma yopiq hisoblanadi.
</br></br>
1.6. Respondentlarning har biri o'z xohishiga ko'ra o'z Hisob qaydnomasida to'plangan shartli ballarni Administrator hamkorlaridan naqd pulga yoki boshqa sodiqlik dasturlariga (Hamkorlarning promo-kodlari, chegirmalari va h.k.) 1.11. bandga muvofiq ravishda almashtirish huquqiga ega. Respondent to'plangan ballar soni bilan interfeysning tegishli bo'limida, shuningdek, veb-saytda tanishishi mumkin.
</br></br>
1.7. Respondentga bir qator talablar qo'yiladi, ularning to'liq ro'yxatini Respondent Foydalanuvchi shartnomasida va har bir dasturiy ta'minot resursining "Qoidalar" bo'limida joylashgan boshqa hujjatlarda ko'rishi mumkin. Administrator, belgilangan talablarga rioya qilinmagan taqdirda, tushuntirishsiz Respondentni Administrator Xizmatlarida ro'yxatdan o'tishini rad etishga haqli.
</br></br>
1.8. Ofertani qabul qilish - Respondent tomonidan Telegram bot va/yoki saytda Hisob qaydnomasini ro'yxatdan o'tkazish (botni faollashtirish/ishga tushirish) va shaxsiy ma'lumotlarni to'ldirish (shaxsiy ma'lumotlarni taqdim etish) bo'yicha ko'rsatilgan harakatlarning bajarilishi hisoblanadi. Administrator va Respondent Oferta shartlarini ham, Foydalanuvchi shartnomasi shartlarini ham, ushbu hujjatlarga mumkin bo'lgan qo'shimchalarni ham qabul qiladilar va ularga rioya qilish majburiyatini oladilar. Tomonlarning mazkur Oferta bilan bevosita tartibga solinmagan huquqiy munosabatlariga O‘zbekiston Respublikasining amaldagi qonunchiligi qoidalari qo‘llaniladi.
</br></br>
Ofertani qabul qilib, Respondent, boshqa narsalar qatori, Administratorga Respondentdan olingan ma'lumotni shaxsiy bo'lmagan shaklda tarqatishga rozilik beradi.
</br></br>
Agar Respondent ushbu Ofertaning birorta bandiga rozi bo'lmasa, Respondent Administrator Xizmatidan foydalanishga haqli emas. Agar Respondent o'zgartirishlar e'lon qilinganidan keyin Administrator Xizmatlaridan foydalanishda davom etsa, u Oferta shartlariga kiritilgan o'zgartirishlarni to'liq qabul qilgan deb hisoblanadi.
</br></br>
1.9. Ro'yxatdan o'tish tartibi Foydalanuvchi shartnomasida ko'rsatilgan.
</br></br>
1.10. Respondent Administratorga taqdim etilgan ma'lumotlarning to'g'riligini tekshirish maqsadida, lekin ular bilan cheklanmagan holda, o'z nomidan turli tashkilotlar va organlarga so'rovlar yuborish huquqini beradi.
</br></br>
1.11. Shartli ballarni naqd pulga olish yoki ulardan sheriklarning sodiqlik dasturlarida foydalanish shartli ballar soniga ma'lum miqdorda erishilgandan keyin mumkin bo'ladi. Minimal miqdor - 25 000 shartli ball. Ushbu miqdor Administratorning xohishiga ko'ra istalgan vaqtda o'zgartirilishi mumkin. To'plangan ballarni konvertatsiya qilish uchun Respondent "Balans" bo'limiga o'tadi, "Pulni yechib olish" ustunini tanlaydi va keyin Xizmatlarda ko'rsatilgan muayyan harakatlarni bajaradi.
</br></br>
1.12. Pul mablag'larini olishda soliq majburiyatlari So'rovnomalar tashkilotchisi bo'lgan Administratorga yuklanadi.
</br></br>
1.13. Ushbu Oferta beg’araz tabiatga ega, shuning uchun iste'molchilar huquqlarini himoya qilish qoidalari ushbu Ofertaga taalluqli emas.
</br></br>
</br></br></br>
<h2>2. Huquq va majburiyatlar</h2>
2.1. Administrator quyidagi huquqlarga ega:
</br></br>
2.1.1. Respondentga ushbu Ofertada va Foydalanuvchi shartnomasida ko‘rsatilgan shartlarga rioya qilgan holda So‘rovnomalardan o’tish uchun Administrator Xizmatlaridan foydalanish imkoniyatini taqdim etish;
</br></br>
2.1.2. istalgan vaqtda, ogohlantirmasdan, ushbu Oferta shartlarini to'ldirish, tahrirlash yoki boshqa tarzda o'zgartirish;
</br></br>
2.1.3. istalgan vaqtda, o'z xohishiga ko'ra, Xizmatlarni qo'shish, bekor qilish yoki o'zgartirish;
</br></br>
2.1.4. Respondentlarni push-bildirishnomalar yuborish yoki boshqa yo'l bilan So'rovnomalarda ishtirok etishga taklif qilish;
</br></br>
2.1.5. So'rovnomaning maqsadli auditoriyasi mezonlariga javob bermaydigan yoki boshqa sabablarga ko'ra Respondentlarni So'rovnomalarda ishtirok etishga taklif qilmaslik/rad etish, shu bilan birga Administrator bunday rad etish sabablarini oshkor qilishga majbur emas;
</br></br>
2.1.6. agar ushbu Oferta shartlaridan boshqacha qoida nazarda tutilmagan bo'lsa, Respondentning So'rovnomalar doirasidagi savollarga javoblarini o'z ichiga olgan shaxsiylashtirilmagan ma'lumotlarni uchinchi shaxslarga topshirish;
</br></br>
2.1.7. Respondent tomonidan Ofertada belgilangan majburiyatlar buzilgan taqdirda yoki boshqa sabablarga ko'ra Respondentning Xizmatlarga kirishini bloklash, ushbu Foydalanuvchi shartnomasini bekor qilish;
</br></br>
2.1.8. ro'yxatdan o'tish paytida ko'rsatilgan raqam bo'yicha Respondentning manziliga har qanday ma'lumotni aniqlashtirish yoki har qanday ma'lumotni yetkazish uchun qo'ng'iroq qilish;
</br></br>
2.1.9. texnik sabablarga ko'ra Xizmatlarni to'xtatib turish.
</br></br>
2.2. Respondent quyidagi huquqlarga ega:
</br></br>
2.2.1. ushbu Oferta va Foydalanuvchi shartnomasida belgilangan tartibda va shartlarda So‘rovnomalarda bepul ishtirok etish;
</br></br>
2.2.2. So'rovnomalarda qatnashish tartibi va shartlari to'g'risida ma'lumot olish;
</br></br>
2.2.3. sabablarini ko'rsatmasdan So'rovnomada qatnashishdan bosh tortish;
</br></br>
2.2.4. Respondentning hisobidagi o‘zi haqidagi ma’lumotlarga, agar bunday o‘zgartirish va tuzatishlar eng oxirgi va ishonchli ma’lumotlarni o‘z ichiga olgan bo‘lsa, mustaqil ravishda o‘zgartirish va tuzatishlar kiritish;
</br></br>
2.2.5. o'z tashabbusi bilan istalgan vaqtda Hisob qaydnomasini o'chirib tashlash. Shu bilan birga, Administrator shaxsiy ma'lumotlardan foydalanishni davom ettirish huquqiga ega.
</br></br>
2.3. Respondentga quyidagi majburiyatlar yuklatiladi:
</br></br>
2.3.1. Administrator Xizmatlarining "Qoidalar" bo'limida joylashgan Foydalanuvchi shartnomasining shartlari va qoidalari bilan mustaqil ravishda tanishish;
</br></br>
2.3.2. Oferta va Foydalanuvchi shartnomasi shartlariga rioya qilish;
</br></br>
2.3.3. Xizmatlarda roʻyxatdan oʻtishda soʻralgan ishonchli maʼlumotlarni taqdim etish, roʻyxatdan oʻtish formasida taklif qilingan barcha masalalar boʻyicha oʻzi haqida aniq, toʻliq va izchil maʼlumotlarni koʻrsatish, taqdim etilgan maʼlumotlarni yangilab turish;
</br></br>
2.3.4. agar amaldagi Hisobvarag’i bo'lsa, Xizmatlarda qayta ro'yxatdan o'tmaslik;
</br></br>
2.3.5. So‘rovnomalarni o‘tkazishda vijdonan harakat qilish, savollarga mazmunli, izchil, aniq va to‘liq javoblar berish. So'rovnoma mavzusiga aloqador bo'lmagan, haqoratli so'zlarni, raqamlar, tinish belgilari va harflar to'plamini o'z ichiga olgan, ma’noviy yuklamaga ega bo'lmagan, bir-biriga yoki sog'lom fikrga zid bo'lgan va h.k. javoblar, shuningdek, ataylab yolg'on javoblar hisobga olinmaydi;
</br></br>
2.3.6. dasturiy ta'minotni nusxalamaslik yoki o'zgartirmaslik; Dasturiy ta'minotga asoslangan dasturlarni yaratmaslik; dastur kodini olish maqsadida dasturiy ta'minotga kirmaslik;
</br></br>
2.3.7. Administrator Xizmatlaridan foydalanish huquqini boshqa har qanday shaklda sotmaslik, boshqa shaxslarga bermaslik, ijaraga bermaslik, uchinchi shaxslarga bermaslik.
</br></br>
</br></br></br>
<h2>3. Boshqa shartlar</h2>
3.1. Ushbu Oferta Respondent ro'yxatdan o'tishni tugatgan va Ofertani qabul qilishga qaratilgan boshqa harakatlarni amalga oshirgan paytdan boshlab kuchga kiradi.
</br></br>
3.2. Ushbu Oferta bo'yicha o'z majburiyatlarini bajarmaslik yoki lozim darajada bajarmaslik bilan bog'liq barcha nizolarni Tomonlar muzokaralar yo'li bilan hal qilishga harakat qiladilar.
</br></br>
3.3. Muzokaralar davomida kelishuvga erishilmagan taqdirda nizolar O‘zbekiston Respublikasining amaldagi qonunchiligiga muvofiq sud tartibida hal etiladi.
</br></br>
<h2>Foydalanuvchi shartnomasi</h2>
Ushbu foydanaluvchi shartnomasi Administrator Xizmatlaridan foydalanish (@insight_web_bot Telegram boti, veb-sayt va boshqa Xizmatlar) hamda ijtimoiy va boshqa So’rovnomalar, anketalar o‘tkazish bo‘yicha Administrator va So’rovnomalarni o'tkazish uchun Ofertani tegishli ravishda qabul qilgan va ushbu foydalanuvchi shartnomasining 1.8-bo'limiga muvofiq ro'yxatdan o'tgan jismoniy shaxs (Foydalanuvchi) o‘rtasidagi munosabatlarni tartibga soladi.
</br></br>
</br></br>
<h2>Atamalar va Ta’riflar</h2>
Ushbu Shartnomada, agar kontekst boshqacha talab qilmasa, quyidagi atamalar quyidagi maʼnolarga ega va uning ajralmas qismi hisoblanadi: «Administrator» - O’zbekiston Respublikasi, Toshkent sh., Mirzo-Ulugbek tumani, Olamchi MFY, Turtarik ko’chasi, 3-5 uy manzilida joylashgan, O‘zbekiston Respublikasi qonun hujjatlariga muvofiq tashkil etilgan va tadbirkorlik faoliyatini amalga oshiruvchi, Respondentlar oʻrtasida soʻrovnomalar oʻtkazish boʻyicha tadbirlar tashkilotchisi, shuningdek, Telegram bot va soʻrovnomalardan oʻtish maqsadida ishlab chiqilgan boshqa resurslarni boshqaruvchi dasturchi bo’lgan yuridik shaxs, "INSIGHT DATA" Ma’suliyati cheklangan jamiyati (STIR 310 439 959).
“Respondent” – 18 yoshga to‘lgan, ijtimoiy yoki boshqa turdagi So’rovnomada ishtirok etuvchi, Ofertada ko‘rsatilgan harakatlarni amalga oshirish orqali ushbu Ofertani qabul qiluvchi jismoniy shaxs.
Xizmat/Telegram bot/veb-sayt – "INSIGHT DATA" MChJ tomonidan ishlab chiqilgan va boshqariluvchi, So’rovnomada qatnashish uchun jismoniy shaxslar kirishini ta'minlashga qaratilgan @insight_web_bot dasturiy ta'minot/ilova/veb-sayti.
"Foydalanuvchi shartnomasi" - umumiy ma'noda Administrator va Foydalanuvchi o'rtasidagi kelishuv. Ushbu Oferta doirasida Foydalanuvchi shartnomasi - Administrator va Respondent o'rtasida Administrator Xizmatlaridan foydalanish shartlari bo'yicha kelishuv.
«So’rovnoma» - tadqiqot predmetini tashkil etuvchi ma'lum fikrlar, bilimlar va ijtimoiy faktlar to'g'risida ma'lumot to'plash va olishdan iborat sotsiologik tadqiqot usuli.
"Shartli ballar/ballar" - So'rovnomani to'ldirganligi yoki Administrator Xizmatlarida u yoki bu harakatni amalga oshirgani uchun Respondentga mukofot sifatida Xizmat qiluvchi va hisoblangan shartli valyuta; ularni so'mga almashtirish yoki Administrator hamkorlarining sodiqlik dasturlarida sarflash mumkin.
Shaxsiy ma'lumotlarni qayta ishlash - Shaxsiy ma'lumotlar bilan avtomatlashtirish vositalaridan foydalangan holda yoki foydalanmasdan amalga oshiriladigan har qanday harakat (operatsiya) yoki harakatlar (operatsiyalar) to'plami, shu jumladan, Shaxsiy ma'lumotlarni yig'ish, ro'yxatga olish, tizimlashtirish, to'plash, saqlash, aniqlashtirish (yangilash, to’ldirish, o'zgartirish), ajratib olish, foydalanish, oshkor qilish, uzatish (tarqatish, boshqalar uchun kirishni ta'minlash), tartibga solish yoki birlashtirish, cheklash, shaxsiylashtirish, bloklash, o'chirish, yo'q qilish.
Ushbu Ofertaning maqsadlari uchun birlikdagi atamalar va ta'riflar ko'plikdagi atamalar va ta'riflarga ham tegishli va aksincha.
</br></br>
<h2>1. Umumiy qoidalar</h2>
1.1. Quyida keltirilgan shartlarni qabul qilgan va muayyan harakatlarni amalga oshirgan taqdirda, jismoniy shaxs (Foydalanuvchi) Foydalanuvchi shartnomasi shartlarini qabul qiladi va o'z zimmasiga olgan majburiyatlarni tasdiqlaydi, bu esa Shartnoma tuzishga tengdir.
</br></br>
1.2. Administrator va Respondent zarur huquqiy va aqliy layoqatga, shuningdek, ushbu Ofertani tuzish va bajarish uchun zarur va yetarli bo'lgan barcha huquq va vakolatlarga ega ekanligiga kafolat beradi.
</br></br>
1.3. Ushbu shartnomaning predmeti Administrator tomonidan ushbu shartnomada va ommaviy Ofertada ko'rsatilgan shartlarda Foydalanuvchilar uchun So’rovnomalar o'tkazish bo'yicha Xizmatlardan foydalanish va foydalanish huquqini ta'minlashdan iborat. Administrator istalgan vaqtda hech qanday maxsus ogohlantirishsiz ushbu Shartnomani o'zgartirish huquqini o'zida saqlab qoladi. Agar Foydalanuvchi Shartnomaga kiritilgan o'zgartirishlar e'lon qilinganidan keyin ham Administrator Xizmatlaridan foydalanishda davom etsa, Foydanaluvchi shu bilan o'zgartirilgan shartlarni qabul qilgan deb hisoblanadi.
</br></br>
1.4. Ushbu Shartnoma bo'yicha Administrator Foydalanuvchiga Xizmatlarga kirish va ulardan foydalanish huquqini beradi. Ushbu Xizmatlar veb-interfeys va ixtisoslashtirilgan apparat va dasturiy ta'minot yordamida Foydalanuvchilar tomonidan kiritilgan ma'lumotlarni uzatish, to'plash va qayta ishlashni, shuningdek, Foydalanuvchilar vaqti-vaqti bilan ishtirok etadigan So'rovnomalar natijalarini elektron shaklda taqdim etishni ta'minlaydi. So'rovnomalar va boshqa tadbirlar yoki harakatlarda ishtirok etish uchun Foydanaluvchilarga shartli ballar beriladi. Administrator o'z xohishiga ko'ra istalgan vaqtda taqdim etilayotgan Xizmatlarning bir qismini yoki barchasini qo'shish, bekor qilish yoki o'zgartirish huquqini o'zida saqlab qoladi.
</br></br>
1.5. Shartli ballarni almashtirish tartibi Foydalanuvchi Administrator Xizmatlarida ro'yxatdan o'tishda o'qishi shart bo'lgan Ofertada keltirilgan.
</br></br>
1.6. Respondent/Foydanaluvchiga qo'yiladigan talablar:
</br></br>
1.6.1. O‘zbekiston Respublikasida yashovchi, 18 yoshga to‘lgan, o‘z ismi va familiyasiga chiqarilgan Uzcard bank kartasining egasi bo‘lgan har qanday jismoniy shaxs Respondent bo‘lishi mumkin.
</br></br>
1.6.2. Respondent Telegram boti va/yoki veb-saytida faqat 1 (bitta) Hisob qaydnomasiga ega bo‘lishi mumkin, u bank kartasi va shaxsiy telefon raqamiga biriktiriladi.
</br></br>
1.6.3. Respondentga faqat bitta Hisob qaydnomasidan foydalanishga ruxsat beriladi. Agar ushbu qoida buzilgan bo'lsa, Administrator sabablarni ko'rsatmasdan Respondentning akkauntini bloklash huquqini o'zida saqlab qoladi.
</br></br>
1.6.4. Har bir Respondent uchun profilni (hisob ma'lumotlarini) to'ldirish majburiydir. Respondent o'zi haqida to'g'ri ma'lumotlarni taqdim etish va profilga o'z vaqtida o'zgartirishlar va qo'shimchalar kiritish majburiyatini oladi, agar bunday holatlar uning hayotida sodir bo'lgan bo'lsa yoki profil parametrlari Xizmatni yaratuvchilar (Administrator) tomonidan kengaytirilgan bo'lsa.
</br></br>
1.6.5. Agar shubhali yoki bir nechta Hisob qaydnomalari aniqlansa, Administrator qo'shimcha identifikatsiyalash jarayonini o'tkazishga, ro'yxatdan o'tish paytida ko'rsatilgan yoki So'rovnomalar davomida ko'rsatilgan har qanday ma'lumotlarni tekshirish yoki aniqlashtirish uchun Foydalanuvchiga telefon qo'ng'iroqlarini amalga oshirish huquqiga ega. Shuningdek, Administrator Foydalanuvchining to'liq ismini, telefon raqamini, bank kartasining oxirgi raqamlarini tasdiqlovchi pasport/boshqa hujjat bilan suratini (selfi) so'rashga haqli.
</br></br>
1.7. Administrator va Foydalanuvchi Foydalanuvchi Shartnomasi va Oferta shartlarini, shuningdek ularga mumkin bo'lgan qo'shimchalarni qabul qiladilar va ularga rioya qilish majburiyatini oladilar. Tomonlarning mazkur shartnoma bilan bevosita tartibga solinmagan huquqiy munosabatlariga O‘zbekiston Respublikasining amaldagi qonunchiligi qoidalari qo‘llaniladi.
</br></br>
Foydalanuvchi shartnomasini qabul qilish orqali Foydalanuvchi, boshqa narsalar qatori, Administratorga Respondentdan olingan ma'lumotlarni shaxsiy bo'lmagan shaklda tarqatishga rozilik beradi.
</br></br>
Agar Foydalanuvchi ushbu Shartnomaning birorta bandiga rozi bo'lmasa, Foydalanuvchi Administrator Xizmatidan foydalanishga haqli emas. Agar Foydanaluvchi o'zgartirishlar e'lon qilinganidan keyin Administrator Xizmatlaridan foydalanishda davom etsa, u Shartnoma shartlariga kiritilgan o'zgartirishlarni to'liq qabul qilgan deb hisoblanadi.
</br></br>
1.8. Administrator Xizmatidan roʻyxatdan oʻtish/qoʻshilish/ishtirok etish/foydalanish tartibi:
</br></br>
1.8.1. Foydalanuvchi taklif havolasini yoki reklama materialida joylashtirilgan havolani bosish orqali botni ishga tushiradi / qo'shiladi. Yoki Foydalanuvchi quyidagi manzilda joylashgan Administrator veb-saytida ro'yxatdan o'tadi: Insight.uz;
</br></br>
1.8.2. Foydalanuvchi botga mobil telefon raqamini kiritadi, so‘ngra muloqot oynasiga shaxsiy ma’lumotlarini, to‘liq ismini, tug‘ilgan sanasini va boshqa so‘ralgan ma’lumotlarni kiritadi. Telefon raqamini taqdim etish Foydalanuvchi avtomatik ravishda Administrator telefon qo'ng'iroqlarini amalga oshirish va ko'rsatilgan telefon raqamiga SMS xabarlar yuborish huquqiga ega bo'lishiga roziligini tasdiqlaydi. Taqdim etilgan ma'lumotlar ro'yxati Administratorning ixtiyoriga ko'ra belgilanadi va istalgan vaqtda o'zgartirilishi mumkin. Pul mablag'larini yechib olish / punktlarni almashtirishda Foydalanuvchi o'z bank kartasining ma'lumotlarini ko'rsatadi.
</br></br>
1.8.3. Taqdim etilgan ma'lumotlar ularning haqiqiyligini tekshirish uchun Administratorga topshiriladi. Administrator tushuntirishsiz Foydalanuvchining Xizmatlarga kirishini rad etish huquqini o'zida saqlab qoladi.
</br></br>
1.8.4. Xizmatdan foydalanishga ruxsat berish to'g'risidagi qaror Administratorning ixtiyoriga ko'ra qabul qilinadi. Administrator hech qanday tushuntirishsiz Foydalanuvchiga kirishni rad etish huquqini o'zida saqlab qoladi.
</br></br>
1.8.5. Ro'yxatdan o'tgandan so'ng Foydanaluvchiga Administrator Xizmatining asosiy funksiyalaridan foydalanish huquqi beriladi. Bu funksiya, lekin ular bilan cheklanmagan holda, "shaxsiy hisob", "So’rovnomani o'tkazish", "hisobni boshqarish", "balans", "yechib olish" va boshqalarni o'z ichiga oladi.
</br></br>
1.8.6. Foydalanuvchi ro'yxatdan o'tgandan so'ng va shu orqali ham Foydalanuvchi shartnomasi, ham Oferta shartlarini qabul qilgandan so'ng, u ma'lum huquqlarga ega bo'lgan holda Xizmatga a'zo bo'ladi. Foydalanuchi taklif qilingan So’rovnomalarda qatnashish va shu orqali "shartli ball" olish huquqiga ega. Shartli ballardan foydalanish tartibi Oferta bilan tartibga solinadi.
</br></br>
1.8.7. Administrator Foydanaluvchi oldida texnik nosozliklar va boshqa hodisalar uchun javobgar emas, agar natijada Foydalanuvchi vaqtinchalik Xizmatlarga kira olmasa yoki So'rovnomani to'ldira olmasa.
</br></br>
1.9. Foydalanuvchi Administratorga taqdim etilgan ma'lumotlarning to'g'riligini tekshirish maqsadida, lekin ular bilan cheklanmagan holda, o'z nomidan turli tashkilotlar va organlarga so'rovlar yuborish huquqini beradi.
</br></br>
</br></br>
<h2>2. Tomonlar huquqlari</h2>
2.1. Administrator quyidagi huquqlarga ega:
</br></br>
2.1.1. Respondentga ushbu Ofertada va Foydalanuvchi shartnomasida ko‘rsatilgan shartlarga rioya qilgan holda So‘rovnomalardan o’tish uchun Administrator Xizmatlaridan foydalanish imkoniyatini taqdim etish;
</br></br>
2.1.2. istalgan vaqtda, ogohlantirmasdan, ushbu Oferta shartlarini to'ldirish, tahrirlash yoki boshqa tarzda o'zgartirish;
</br></br>
2.1.3. istalgan vaqtda, o'z xohishiga ko'ra, Xizmatlarni qo'shish, bekor qilish yoki o'zgartirish;
</br></br>
2.1.4. Foydalanuvchi tomonidan Shartnomada belgilangan majburiyatlar buzilganda yoki boshqa sabablarga ko'ra Foydalanuvchining Xizmatlarga kirishini bloklash, ushbu Foydalanuvchi shartnomasini bekor qilish;
</br></br>
2.1.5. texnik sabablarga ko'ra Xizmatlarni to'xtatib turish.
</br></br>
2.2. Administratorga quyidagi majburiyatlar yuklatiladi:
</br></br>
2.2.1. ushbu Shartnoma shartlari va maxfiylik qoidalariga muvofiq Foydanaluvchining Shaxsiy ma'lumotlarini qayta ishlash, ularni maxfiy saqlash va xavfsizligini ta'minlash;
</br></br>
2.3. Respondent quyidagi huquqlarga ega:
</br></br>
2.3.1. barcha talablarga rioya qilgan va Shartnomada ko'rsatilgan shartlarni bajargan taqdirda, Administrator Xizmatlaridan cheklangan ravishda foydalanish;
</br></br>
2.3.2. Respondentning Hisob qaydnomasidagi o‘zi haqidagi ma’lumotlarga, agar bunday o‘zgartirish va tuzatishlar eng oxirgi va ishonchli ma’lumotlarni o‘z ichiga olgan bo‘lsa, mustaqil ravishda o‘zgartirish va tuzatishlar kiritish;
</br></br>
2.3.3. o'z tashabbusi bilan istalgan vaqtda Hisob qaydnomasini o'chirib tashlash;
</br></br>
2.4. Respondentga quyidagi majburiyatlar yuklatiladi:
</br></br>
2.4.1. mustaqil ravishda barcha shartlar bilan tanishish va ushbu Shartnoma va Oferta shartlariga rioya qilish;
</br></br>
2.4.2. Xizmatlarda roʻyxatdan oʻtishda soʻralgan ishonchli maʼlumotlarni taqdim etish, roʻyxatdan oʻtish formasida taklif qilingan barcha masalalar boʻyicha oʻzi haqida aniq, toʻliq va izchil maʼlumotlarni koʻrsatish, taqdim etilgan maʼlumotlarni yangilab turish;
</br></br>
2.4.3. agar amaldagi Hisob qaydnomasi bo'lsa, Xizmatlarda qayta ro'yxatdan o'tmaslik;
</br></br>
2.4.4. dasturiy ta'minotni nusxalamaslik yoki o'zgartirmaslik; Dasturiy ta'minotga asoslangan dasturlarni yaratmaslik; dastur kodini olish maqsadida dasturiy ta'minotga kirmaslik;
</br></br>
2.4.5. Administrator Xizmatlaridan foydalanish huquqini boshqa har qanday shaklda sotmaslik, boshqa shaxslarga bermaslik, ijaraga bermaslik, uchinchi shaxslarga bermaslik.
</br></br>
</br></br>
<h2>3. Kirishni bloklash</h2>
3.1. Administrator tomonidan Xizmatlarga kirishni bloklash quyidagi hollarda amalga oshiriladi:
</br></br>
3.1.1. Foydalanuvchi tomonidan o’zining hisobini o'chirish;
</br></br>
3.1.2. Foydalanuvchi tomonidan Administratorga yozma ariza yuborish orqali amalga oshiriladigan shaxsiy ma'lumotlarni qayta ishlashga roziligini qaytarib olish;
</br></br>
3.1.3. Foydalanuvchining maqomida uning talablariga muvofiqligi nuqtai nazaridan o'zgarishlar;
</br></br>
3.1.4. Foydalanuvchi tomonidan Shartnoma va Ofertada belgilangan majburiyatlarni buzganlik holatini aniqlash.
</br></br>
3.2. Agar Foydalanuvchi Xizmatdan foydalanmasa va oxirgi 6 (olti) oy davomida Xizmat orqali o‘tkazilgan birorta So‘rovnomada qatnashmagan bo‘lsa, Foydalanuvchi Hisob qaydnomasi bloklanishi mumkin.
</br></br>
3.3. Foydalanuvchi Administrator uning oldida Xizmatga kirishni bloklagani uchun javobgar emasligiga rozilik bildiradi.
</br></br>
3.4. Foydalanuvchining Xizmatga kirishi bloklangan kundan boshlab ushbu Shartnoma, shuningdek Oferta bekor qilingan hisoblanadi.
</br></br>
</br></br>
<h2>4. Intellektual mulk huquqlari</h2>
4.1. Administrator yoki uning mijozlarining intellektual mulki:
</br></br>
4.1.1. Foydalanuvchi Administrator yoki uning mijozlarining intellektual mulki qonuniy himoya ostida ekanligini tan oladi va rozilik beradi.
</br></br>
4.1.2. Foydalanuvchiga So'rovnomalarda qatnashish uchun zarur bo'lgan darajada Xizmat va Dasturiy ta'minotdan foydalanish uchun bepul oddiy (eksklyuziv bo'lmagan) litsenziya beriladi.
</br></br>
4.1.3. Foydalanuvchiga Administratorning yoki uning mijozlarining intellektual mulkiga boshqa huquqlar berilmaydi, shu jumladan Foydalanuvchiga Administratorning yoki uning mijozlarining intellektual mulkini o'tkazish, begonalashtirish, sotish, nusxalash, tarqatish, o'zgartirish va boshqa yo'llar bilan tasarruf etish taqiqlanadi, ushbu Shartnomaning oldingi bandida ko'rsatilgan huquqlar bundan mustasno.
</br></br>
4.2. Foydalanuvchining intellektual mulki:
</br></br>
4.2.1. So'rovnoma davomida har qanday materiallarni joylashtirish orqali Foydalanuvchi Administratorga va uning mijozlariga (So'rovnoma tashkilotchisi va/yoki buyurtmachisi) ushbu materiallar uchun hudud va uning amal qilish muddatini cheklamasdan materiallardan qonun hujjatlarida taqiqlanmagan har qanday usulda, shu jumladan ommaviy takrorlash, namoyish qilish, nusxalash, raqamli qayta ishlash, qayta ishlash yoki o‘zgartirish yo‘li bilan foydalanish, shuningdek yuqoridagi huquqlarni uchinchi shaxslarga berish (sublitsenziyalar berish), shu jumladan transchegaraviy uzatish huquqi bilan bepul oddiy (eksklyuziv bo'lmagan) litsenziya beradi;
</br></br>
4.2.2. Xizmatda materiallarni joylashtirish orqali Foydalanuvchi quyidagilarni kafolatlaydi:
– e’lon qilingan materiallarning qonuniy egasi hisoblanadi va materiallarni nashr etish orqali Foydalanuvchi uchinchi shaxslarning huquqlarini yoki amaldagi shartnomalar shartlarini buzmaydi;
– Foydalanuvchi materiallarga, shu jumladan maxfiylikka nisbatan hech qanday majburiyatlarga ega emas.
</br></br>
<h2>5. Maxfiylik va Shaxsiy ma’lumotlar</h2>
5.1. Respondentning shaxsiy ma'lumotlarini qayta ishlashda Administrator Operator sifatida ishlaydi.
</br></br>
Operator Respondentning shaxsiy ma'lumotlarini quyidagi maqsadlarda qayta ishlaydi:
— soʻrovnomalar oʻtkazish va soʻrovlarda Respondentning ishtirokini tashkil etish;
— Operator Respondentni aniq So'rovnomalarga yaxshiroq yo'naltirishi uchun Sayt Xizmatlarini shaxsiylashtirish (aniq Respondent uchun Sayt Xizmatlarini moslashtirish);
— Respondentning rag‘batlantirish dasturlarida ishtirokini ta’minlash;
— Respondent bilan aloqa qilish, shu jumladan Administrator Xizmatlaridan foydalanish bo'yicha bildirishnomalar, so'rovlar va ma'lumotlarni yuborish, shuningdek Respondentning So’rovnomalari va arizalarini ko'rib chiqish;
— Administrator Xizmatlari sifati, ulardan foydalanish qulayligini oshirish, Xizmatlarning qo‘shimcha funksionalligini rivojlantirish, uchinchi tomon axborot tizimlarini sinovdan o‘tkazish;
— Respondent tomonidan taqdim etilgan ma’lumotlarni tekshirish;
— Foydalanuvchi shartnomasi, Oferta buzilishi holatlarini aniqlash va oldini olish;
— firibgarlik yoki uchinchi shaxslarning huquqlari buzilishi gumon qilinayotgan holatlarni tekshirish;
— amaldagi qonun hujjatlarida belgilangan majburiyatlarni bajarish, shu jumladan davlat organlari yoki boshqa vakolatli shaxslarning tegishli tartibda vakolat berilgan so‘rovlariga javob berish.
5.2. Respondent haqidagi har qanday ma'lumotlar uning roziligi bilan qayta ishlanadi: Foydalanuvchi shartnomasi va Ofertani qabul qilib, Respondent o'z xohishiga ko'ra va o'z manfaatlarini ko'zlab, Operatorga o'z shaxsiy ma'lumotlarini belgilangan shartlarda qayta ishlashga rozilik beradi.
</br></br>
5.3. Operator Respondentning Shaxsiy ma'lumotlarini avtomatlashtirish vositalaridan foydalangan holda yoki foydalanmasdan amalga oshiriladigan Shaxsiy ma'lumotlar bilan quyidagi harakatlar (operatsiyalar) orqali qayta ishlaydi, xususan: Shaxsiy ma'lumotlarni yig'ish, ro'yxatga olish, tizimlashtirish, to'plash, saqlash, aniqlashtirish (yangilash, to’ldirish, o'zgartirish), ajratib olish, foydalanish, oshkor qilish, uzatish (tarqatish, boshqalar uchun kirishni ta'minlash), tartibga solish yoki birlashtirish, cheklash, shaxsiylashtirish, bloklash, o'chirish, yo'q qilish, transchegaraviy uzatish.
</br></br>
5.4. Maxfiy ma'lumotlarni Foydanaluvchi tomonidan uchinchi shaxslarga o'tkazish, shuningdek, Maxfiy ma'lumotlarni oshkor qilish va unga boshqa har qanday kirish faqat Administrator yoki uning mijozining yozma ruxsati bilan amalga oshirilishi mumkin.
</br></br>
5.5. Foydalanuvchi, o'z navbatida, Maxfiy ma'lumotlarni saqlash uchun barcha choralarni ko'radi.
</br></br>
5.6. Maxfiy ma'lumotlarni oshkor qilganlik uchun Foydalanuvchi O‘zbekiston Respublikasi qonun hujjatlarida belgilangan javobgarlikka tortiladi.
</br></br>
5.7. Yuqoridagi javobgarlikka qo'shimcha ravishda, Foydalanuvchi Maxfiy ma'lumotlar oshkor etilsa, Foydalanuvchi Administrator Xizmatlaridagi Hisob qaydnomasiga kirish huquqidan mahrum qilinishiga ham rozilik beradi. Bu Hisob qaydnomasi bloklanishi mumkin.
</br></br>
</br></br>
<h2>6. Boshqa shartlar</h2>
6.1. Ushbu Shartnoma Respondent ro'yxatdan o'tishni tugatgan va Shartnomani qabul qilishga qaratilgan boshqa harakatlarni amalga oshirgan paytdan boshlab kuchga kiradi.
</br></br>
6.2. Ushbu Shartnoma bo'yicha o'z majburiyatlarini bajarmaslik yoki lozim darajada bajarmaslik bilan bog'liq barcha nizolarni Tomonlar muzokaralar yo'li bilan hal qilishga harakat qiladilar.
</br></br>
6.3. Muzokaralar davomida kelishuvga erishilmagan taqdirda nizolar O‘zbekiston Respublikasining amaldagi qonunchiligiga muvofiq sud tartibida hal etiladi.`,
  ru: `<h2>Термины и определения</h2>
В настоящей Oферте, если из контекста не следует иное, нижеприведенные термины имеют следующие значения и являются её составной неотъемлемой частью:
«Администратор» - юридическое лицо, Общество с ограниченной ответственностью "INSIGHT DATA" (ИНН 310 439 959), местонахождение по адресу: Республика Узбекистан г. Ташкент, Мирзо-Улугбекский район, Олмачи МФЙ, ул. Туртарик дом 3-5, созданное в соответствии с законодательством Республики Узбекистан и осуществляющее предпринимательскую деятельность, являющееся организатором активностей, связанных с проведением опросов респондентов, а также разработчиком, проводящим администрирование Телеграмм бота и прочих ресурсов, разработанных с целью прохождения опросов.
«Респондент» - физическое лицо, достигшее 18-летнего возраста, принимающее участие в социологическом или ином опросе, анкетировании, которое акцептирует данную Оферту посредством совершения действий, приведенных в Оферте.
Сервис/Телеграмм-бот/web-сайт – @insight_web_bot программное обеспечение/приложение/сайт, разработанное и администрируемое ООО «INSIGHT DATA», направленное на предоставление доступа физическим лицам к участию в анкетировании/прохождению опросов.
«Пользовательское соглашение» - в общем смысле, договор между Администратором и Пользователем. В рамках настоящей оферты Пользовательским соглашением признается договор между Администратором и Респондентом, касающийся условий использования Сервисов Администратора.
«Опрос» - метод социологического исследования, заключающийся в сборе и получении сведений об определённых мнениях, знаниях и социальных фактах, составляющих предмет исследования.
«Условные баллы/Баллы» - условная валюта, выступающая и зачисляющаяся в качестве вознаграждения Респонденту за прохождение Опроса или выполнения того или иного действия в Сервисах Администратора, которая может быть обменяна на узбекский сум или использована в программах лояльности партнёров Администратора.
Для целей настоящей оферты термины и определения в единственном числе относятся также и к терминам и определениям во множественном числе и наоборот.
</br></br>
<h2>1. Общие положения</h2>
1.1. В соответствии со статьей 369 Гражданского Кодекса Республики Узбекистан данный документ является публичной офертой, адресованной физическим лицам, и в случае принятия изложенных ниже условий и совершения определенных действий, физическое лицо (Респондент) акцептирует настоящую оферту Администратора, что является равносильным заключению Договора.
</br></br>
1.2. Администратор и Респондент гарантируют, что обладают необходимой право- и дееспособностью, а также всеми правами и полномочиями, необходимыми и достаточными для заключения и исполнения настоящей оферты.
</br></br>
1.3. Предметом настоящей оферты является предложение Администратором своих Сервисов по прохождению Опросов (Телеграмм бот, web-сайт, мобильное приложение и прочее) Респондентам на условиях, указанных в настоящей оферте. Администратор сохраняет за собой право изменять настоящую Оферту в любое время без какого-либо специального уведомления. Если Респондент продолжает пользоваться услугами Администратора после публикации изменений в оферте, считается, что Респондент тем самым принимает изменения условий оферты.
</br></br>
1.4. Настоящей офертой Администратор предлагает неограниченному кругу лиц принимать участие в онлайн-опросах, посредством Телеграмм-бота @insight_web_bot и иных Сервисов Администратора. Данные Сервисы обеспечивают с помощью веб-интерфейса insight.uz, и специализированного аппаратно-программного обеспечения передачу, накопление и обработку информации, введённой Респондентами, а также результатов Опросов, в которых Респонденты периодически принимают участие, представленных в электронном виде. За участие в Опросах Респондентам начисляются условные баллы/баллы. Администратор оставляет за собой право по собственному усмотрению добавлять, отменять или изменять некоторые или все из предлагаемых услуг в любое время.
</br></br>
1.5. Опросы могут носить как массовый характер, так и узконаправленный характер. За каждый вопрос выплачивается вознаграждение в условных баллах, которые начисляются после завершения всего Опроса. При заполнении анкеты и иных активностей, требующихся при регистрации, Администратор вправе установить стоимость каждого вопроса по своему усмотрению.
</br></br>
1.5.2. Моментом окончания Опроса является получение ответов на все вопросы, приведённые в Опросе. Если установлен срок проведения Опроса, и данный срок истечет ранее, чем Респондент даст ответы на все вопросы – данный Опрос будет считаться недействительным и условные баллы по нему не начисляются.
</br></br>
1.5.3. По достижению определенных показателей (истечению срока действия, сбора требующихся данных, получения ответов на все поставленные вопросы и т.п.), Опрос считается закрытым.
</br></br>
1.6. Любой из Респондентов вправе по своему усмотрению обменять накопленные на его счете условные баллы на денежные средства или иные программы лояльности от партнёров Администратора (промо-коды Партнеров, скидки и прочее), в порядке, согласно пункту 1.11. С количеством накопленных баллов Респондент может ознакомиться в соответствующем разделе интерфейса, а также на web-сайте.
</br></br>
1.7. К Респонденту предъявляется ряд требований, ознакомиться с полным перечнем которых Респондент может в Пользовательском соглашении и иных документах, находящихся в разделе «Правила» в каждом программном ресурсе. Администратор вправе отказать Респонденту в регистрации на Сервисах Администратора, в случае несоответствия указанным требованиям, без объяснения причин.
</br></br>
1.8. Акцептом Оферты признается совершение Респондентом конклюдентных действий, выраженных в регистрации Учетной записи в Телеграмм боте и/или на сайте (активация/запуск бота) и заполнении анкетных данных (предоставление персональных данных). Администратор и Респондент принимают и обязуются соблюдать как условия Оферты, так и условия Пользовательского соглашения, и возможных дополнений к указанным документам. К правоотношениям сторон, прямо неурегулированным настоящей офертой, применяются положения действующего законодательства Республики Узбекистан.
</br></br>
Акцептуя Оферту, Респондент, в том числе дает Администратору согласие на распространение полученных сведений от Респондента в обезличенном виде.
</br></br>
В случае несогласия Респондента с какими-либо из положений настоящей Оферты, Респондент не вправе использовать Сервис Администратора. Если Респондент продолжает пользоваться Сервисами Администратора после публикации изменений, считается, что, тем самым, он принимает изменения условий Оферты в полном объёме.
</br></br>
1.9. Порядок регистрации приводится в Пользовательском соглашении.
</br></br>
1.10. Респондент уполномочивает Администратора направлять запросы в различные органы и инстанции от своего имени, с целю, но не ограничиваясь, проведения проверки достоверности предоставленных данных.
</br></br>
1.11. Вывод условных баллов в денежные средства либо их использование в партнёрских программах лояльности становится доступным при достижении определенного количества условных баллов. Минимальная сумма – 25 000 условных баллов. Данная сумма может быть изменена в любой момент по усмотрению Администратора. Для того, чтобы конвертировать накопленные балы, Респондент переходит в раздел «Баланс», выбирает графу – «Вывод средств» и далее выполняет определенные действия, указанные в Сервисах.
</br></br>
1.12. При выводе денежных средств налоговые обязательства возлагаются на Администратора, выступающего организатором проведения Опросов.
</br></br>
1.13. Настоящая Оферта носит безвозмездный характер, в связи с чем нормы о защите прав потребителей к настоящей Оферте не применяются.
</br></br>
</br></br>
<h2>2. Права и обязанности</h2>
2.1. Администратор вправе:
</br></br>
2.1.1. предоставить Респонденту возможность использовать Сервисы Администратора для прохождения Опросов при условии соблюдения Респондентом условий, указанных в настоящей Оферте и Пользовательском соглашении;
</br></br>
2.1.2. в любое время, без уведомления дополнять, редактировать либо иным образом вносить изменения в условия настоящей Оферты;
</br></br>
2.1.3. в любое время по собственному усмотрению добавлять, отменять или изменять Сервисы;
</br></br>
2.1.4. приглашать Респондентов для участия в Опросах посредством направления push-уведомлений или иным доступным способом;
</br></br>
2.1.5. не приглашать/отказывать Респондентам в участии в Опросах, не удовлетворяющим критериям целевой аудитории Опроса либо по иным причинам, при этом Администратор не обязан раскрывать причины таких отказов;
</br></br>
2.1.6. если иное прямо не следует из условий настоящей Оферты, передавать обезличенную информацию, содержащую ответы Респондента на вопросы в рамках Опросов, третьим лицам;
</br></br>
2.1.7. заблокировать Респонденту доступ к Сервисам, расторгнуть настоящее Пользовательское соглашение при нарушении Респондентом обязанностей, установленных Офертой или по другим основаниям;
</br></br>
2.1.8. совершать телефонные звонки в адрес Респондента, по номеру, указанному при регистрации, для уточнения какой-либо информации или сообщения каких-либо сведений;
</br></br>
2.1.9. по техническим причинам приостановить работу Сервисов;
</br></br>
2.2. Респондент вправе:
</br></br>
2.2.1. бесплатно участвовать в Опросах в порядке и на условиях, установленных настоящей Офертой и Пользовательским соглашением;
</br></br>
2.2.2. получать информацию о порядке и условиях участия в Опросах;
</br></br>
2.2.3. отказаться от участия в Опросе без объяснения причин;
</br></br>
2.2.4. самостоятельно вносить изменения и исправления в информацию о себе в Учетной записи Респондента, при условии, что такие изменения и исправления содержат актуальную и достоверную информацию;
</br></br>
2.2.5. удалить свою Учётную запись в любое время по собственной инициативе. При этом Администратор имеет право продолжить использование персональных данных.
</br></br>
2.3. Респондент обязуется:
</br></br>
2.3.1. самостоятельно ознакомиться с условиями и положениями Пользовательского соглашения, находящегося в разделе «Правила» Сервисов Администратора;
</br></br>
2.3.2. соблюдать условия Оферты и Пользовательского соглашения;
</br></br>
2.3.3. предоставлять достоверные данные, запрашиваемые при Регистрации в Сервисах, указывать точную, полную и непротиворечивую информацию о себе по всем вопросам, предлагаемым в регистрационной форме, поддерживать предоставленную информацию в актуальном состоянии;
</br></br>
2.3.4. не проходить повторную Регистрацию в Сервисах при наличии действующей Учетной записи;
</br></br>
2.3.5. при прохождении Опросов действовать добросовестно, давать информативные, содержательные, последовательные, непротиворечивые, а также полные ответы на вопросы. Несодержательными считаются ответы, не относящиеся к теме Опроса, содержащие ненормативную лексику, набор цифр, знаков препинания и букв, не имеющих смысловой нагрузки, противоречащие друг другу или здравому смыслу и т.п., а также заведомо ложные ответы;
</br></br>
2.3.6. не копировать и не изменять Программное обеспечение; не создавать программы, производные от Программного обеспечения; не проникать в Программное обеспечение с целью получения программного кода;
</br></br>
2.3.7. не осуществлять продажу, уступку, сдачу в аренду, передачу третьим лицам в любой иной форме прав доступа к Сервисам Администратора;
</br></br>
</br></br>
<h2>3. Прочие условия</h2>
3.1. Настоящая оферта вступает в силу с момента прохождения Респондентом регистрации и совершения иных действий, направленных на акцептирование Оферты.
</br></br>
3.2. Все споры, связанные с неисполнением или ненадлежащим исполнением своих обязательств по настоящей Оферте, Стороны будут стараться решить путем переговоров.
</br></br>
3.3. В случае недостижения согласия в ходе переговоров, споры будут разрешаться в судебном порядке в соответствии с действующим законодательством РУз.
</br></br>
<h2>Пользовательское соглашение</h2>
Настоящее пользовательское соглашение регулирует отношения по использованию сервисов Администратора (Телеграмм-бот @insight_web_bot, web-сайта и иных сервисов) и прохождению социальных и иных опросов, анкетирования, между Администратором и физическим лицом (Пользователем), надлежащим образом, акцептировавшим Оферту по прохождению опросов и зарегистрировавшимся в соответствии с разделом 1.8. настоящего пользовательского соглашения.
</br></br>
<h2>Термины и определения</h2>
В настоящем соглашении, если из контекста не следует иное, нижеприведенные термины имеют следующие значения и являются его составной неотъемлемой частью:
«Администратор» - юридическое лицо, Общество с ограниченной ответственностью "INSIGHT DATA" (ИНН 310 439 959), местонахождение по адресу: Республика Узбекистан, г. Ташкент, Мирзо-Улугбекский район, Олмачи МФЙ, ул. Туртарик дом 3-5, созданное в соответствии с законодательством Республики Узбекистан и осуществляющее предпринимательскую деятельность и являющееся разработчиком, проводящим администрирование Телеграмм-бота и прочих ресурсов (сервисов), разработанных с целью прохождения опросов и анкетирования, а также организатором проведения указанных опросов респондентов.
«Пользователь» - физическое лицо, получающее право пользования Сервисами Администратора принимающее участие в социологическом или ином опросе, анкетировании, которое акцептирует Оферты Администратора посредством совершения действий, приведенных в Оферте.
Сервис/Телеграмм-бот/web-сайт – @insight_web_bot программное обеспечение/приложение/сайт, разработанное и администрируемое ООО «INSIGHT DATA», направленное на предоставление доступа физическим лицам к участию в анкетировании/прохождению опросов.
«Оферта» - предложение неограниченному кругу лиц о заключении сделки, с изложением существенных условий. В контексте настоящего соглашения является предложением неограниченному кругу лиц прохождения участия в акциях Администратора (опросах, анкетировании и прочем) на Сервисах Администратора.
«Опрос» - метод социологического исследования, заключающийся в сборе и получении сведений об определённых мнениях, знаниях и социальных фактах, составляющих предмет исследования.
«Условные баллы/баллы» - условная валюта, выступающая и зачисляющаяся в качестве вознаграждения Пользователю за прохождение Опросов или выполнения того или иного действия в Сервисах Администратора.
Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с Персональными данными, включая сбор, запись, структурирование/ систематизацию, накопление, хранение, уточнение (обновление, изменение, модификацию), извлечение, использование, раскрытие, передача (распространение, предоставление иным способом, доступ), упорядочивание или комбинирование, ограничение, обезличивание, блокирование, удаление, уничтожение Персональных данных (стирание или разрушение).
Для целей настоящего соглашения термины и определения в единственном числе относятся также и к терминам и определениям во множественном числе и наоборот.
</br></br>
<h2>1. Общие положения </h2>
1.1. В случае принятия изложенных ниже условий и совершения определенных действий, физическое лицо (Пользователь) принимает условия Пользовательского соглашения и подтверждает принятые обязательства, что является равносильным заключению Договора.
</br></br>
1.2. Администратор и Пользователь гарантируют, что обладают необходимой право- и дееспособностью, а также всеми правами и полномочиями, необходимыми и достаточными для заключения и исполнения настоящего соглашения.
</br></br>
1.3. Предметом настоящего соглашения является предоставление Администратором права доступа и пользования Сервисами по прохождению Опросов Пользователям на условиях, указанных в настоящем соглашении и публичной Оферте. Администратор сохраняет за собой право изменять настоящее Соглашение в любое время без какого-либо специального уведомления. Если Пользователь продолжает пользоваться услугами Администратора после публикации изменений в Соглашении, считается, что Пользователь тем самым принимает измененные условия.
</br></br>
1.4. Настоящим Соглашением Администратор предоставляет Пользователем право доступа и пользования Сервисами. Данные Сервисы обеспечивают, с помощью веб-интерфейса и специализированного аппаратно-программного обеспечения передачу, накопление и обработку информации, введённой Пользователями, а также результатов Опросов, в которых Пользователи периодически принимают участие, представленных в электронном виде. За участие в Опросах и иных активностях или действиях, Пользователям начисляются условные баллы. Администратор оставляет за собой право по собственному усмотрению добавлять, отменять или изменять некоторые или все из предлагаемых услуг в любое время.
</br></br>
1.5. Порядок обмена условных баллов приводится в Оферте, ознакомится с которой Пользователь обязан при регистрации в Сервисах Администратора.
</br></br>
1.6. Требования, предъявляемые к Респонденту/Пользователю:
</br></br>
1.6.1. Респондентом может стать любое физическое лицо, проживающее в Республики Узбекистан, достигшее 18 лет, являющееся держателем, выданной на свою собственную фамилию и имя, банковской карты Uzcard.
</br></br>
1.6.2. Респондент может иметь только 1 (один) Учетную запись, зарегистрированный в Телеграмм боте и/или на сайте, который будет привязан к банковской карте и личному номеру телефона.
</br></br>
1.6.3. Респонденту разрешается пользоваться только одной Учетной записью. При нарушении данного правила Администратор оставляет за собой право без объяснения причин блокировать Учетную запись респондента.
</br></br>
1.6.4. Заполнение профиля (учетных данных) для каждого респондента обязательно. Респондент обязуется предоставить о себе правдивую информацию и своевременно вносить изменения в профиль и дополнения, если такие произошли в его жизни или если параметры профиля расширены создателями Сервиса (Администратором).
</br></br>
1.6.5. При выявлении подозрительных Учетных записей или множества Учетных записей Администратор вправе проводить процедуру дополнительной идентификации, совершать телефонные звонки в адрес Пользователя с целью провести верификацию или уточнить какую-либо информацию, указанную при регистрации или указанную при прохождении Опросов. Администратор также вправе запросить фотографию (селфи) с паспортом/иным документом, подтверждающим ФИО, номер телефона, последние цифры банковской карты.
</br></br>
1.7. Администратор и Пользователь принимают и обязуются соблюдать как условия Пользовательского соглашения и Оферты, так и возможных дополнений к ним. К правоотношениям сторон, прямо неурегулированным настоящим соглашением, применяются положения действующего законодательства Республики Узбекистан.
</br></br>
Акцептуя Пользовательское соглашение, Пользователь, в том числе, дает Администратору согласие на распространение полученных сведений от Респондента в обезличенном виде.
</br></br>
В случае несогласия Пользователя с какими-либо из положений настоящей Соглашения, Пользователь не вправе использовать Сервис Администратора. Если Пользователь продолжает пользоваться Сервисами Администратора после публикации изменений, считается, что, тем самым, он принимает изменения условий Соглашения в полном объёме.
</br></br>
1.8. Порядок регистрации/присоединения/участия/использования Сервиса Администратора:
</br></br>
1.8.1. Пользователь запускает/присоединяется к боту путем перехода по ссылке-приглашению, или ссылке, размещенной в рекламном материале. Или Пользователь проходит регистрацию на web-сайте Администратора, расположенного по адресу: Insight.uz ;
</br></br>
1.8.2. Пользователь предоставляет боту номер мобильного телефона, затем вводит в диалоговом окне свои персональные данные, ФИО, дату рождения и иные запрошенные данные. Предоставление телефонного номера Пользователь автоматически подтверждает свое согласие на то, что Администратор будет вправе совершать телефонные звони, так и направлять СМС-сообщения на указанный телефонный номер. Перечень предоставляемых данных определяется на усмотрение Администратора и может быть изменен в любое время. При выводе средств/обмене баллов, Пользователь указывает реквизиты собственной банковской карты.
</br></br>
1.8.3. Предоставленные данные передаются Администратору на проведение процедуры проверки на предмет достоверности предоставленных данных. Администратор оставляет за собой право отказать Пользователю в предоставлении доступа к Сервисам без объяснения причин.
</br></br>
1.8.4. Решение о предоставлении доступа к использованию Сервиса принимается на усмотрение Администратора. Администратор оставляет за собой право отказать Пользователю в предоставлении доступа без каких-либо объяснений.
</br></br>
1.8.5. После прохождения регистрации Пользователю предоставляется доступ к основному функционалу Сервиса Администратора. В данный функционал входит, но не ограничивается, «личный кабинет», «прохождение опроса», «управление Учетной записью», «баланс», «вывод средств» и т.д.
</br></br>
1.8.6. После того, как Пользователь проходит регистрацию и тем самым акцептирует условия как Пользовательского соглашения, так и Оферты, он становится участником Сервиса, обретая определенные права. Участник вправе участвовать в предлагаемых опросах, тем самым зарабатывая «условные баллы». Порядок использования условных баллов регулируется Офертой.
</br></br>
1.8.7. Администратор не несет ответственность перед Пользователем за технические сбои и иные происшествия, в следствии которых Пользователь временно не мог получить доступ к Сервисам или не мог завершить прохождение Опроса.
</br></br>
1.9. Пользователь уполномочивает Администратора направлять запросы в различные органы и инстанции от своего имени, с целю, но не ограничиваясь, проведения проверки достоверности предоставленных данных.
</br></br>
</br></br>
<h2>2. Права сторон </h2>
2.1. Администратор вправе:
</br></br>
2.1.1. предоставить Пользователю возможность использовать Сервисы Администратора при условии соблюдения Пользователя условий, указанных в настоящем Соглашении и Оферте;
</br></br>
2.1.2. в любое время, без уведомления, дополнять, редактировать либо иным образом вносить изменения в условия настоящего Соглашения;
</br></br>
2.1.3. в любое время по собственному усмотрению добавлять, отменять или изменять Сервисы;
</br></br>
2.1.4. заблокировать Пользователю доступ к Сервисам, расторгнуть настоящее Пользовательское соглашение при нарушении Пользователем обязанностей, установленных Соглашением или по другим основаниям;
</br></br>
2.1.5. по техническим причинам приостановить работу Сервисов.
</br></br>
2.2. Администратор обязуется:
</br></br>
2.2.1. обрабатывать Персональные данные Пользователя, сохранять их конфиденциальность и обеспечивать безопасность в соответствии с условиями настоящего Соглашения и положениями о конфиденциальности.
</br></br>
2.3. Пользователь вправе:
</br></br>
2.3.1. при соответствии всем требованиям и выполнении приведенных в Соглашении условий получить ограниченный доступ к Сервисам Администратора;
</br></br>
2.3.2. самостоятельно вносить изменения и исправления в информацию о себе в Учетной записи Пользователя, при условии, что такие изменения и исправления содержат актуальную и достоверную информацию;
</br></br>
2.3.3. удалить свою Учётную запись в любое время, по собственной инициативе.
</br></br>
2.4. Пользователь обязуется:
</br></br>
2.4.1. самостоятельно ознакомиться со всеми условиями и соблюдать данные условия настоящего Соглашения и Оферты;
</br></br>
2.4.2. предоставлять достоверные данные, запрашиваемые при Регистрации в Сервисах, указывать точную, полную и непротиворечивую информацию о себе по всем вопросам, предлагаемым в регистрационной форме, поддерживать предоставленную информацию в актуальном состоянии;
</br></br>
2.4.3. не проходить повторную Регистрацию в Сервисе при наличии действующей Учетной записи;
</br></br>
2.4.4. не копировать и не изменять Программное обеспечение; не создавать программы, производные от Программного обеспечения; не проникать в Программное обеспечение с целью получения программного кода;
</br></br>
2.4.5. не осуществлять продажу, уступку, сдачу в аренду, передачу третьим лицам в любой иной форме прав доступа к Сервису Администратора.
</br></br>
</br></br>
<h2>3. Блокировка доступа </h2>
3.1. Блокировка доступа Администратором к Сервисам осуществляется в следующих случаях:
</br></br>
3.1.1. удаления Пользователем своей Учётной записи;
</br></br>
3.1.2. отзыва Пользователем согласия на обработку персональных данных, который производится путем направления письменного заявления в адрес Администратора;
</br></br>
3.1.3. изменения статуса Пользователя в части соответствия его требованиям, указанным в настоящей;
</br></br>
3.1.4. выявления факта нарушения Пользователем обязательств, установленных в Соглашении и Оферте.
</br></br>
3.2. Учетная запись Пользователя может быть заблокирована, если Пользователь не пользуется Сервисом и не принял участия ни в одном Опросе, проводимом через Сервис, в течение 6 (шести) предыдущих месяцев.
</br></br>
3.3. Пользователь соглашается с тем, что Администратор не несет перед ним никакой ответственности за блокировку доступа к Сервису.
</br></br>
3.4. С даты блокировки доступа Пользователя к Сервису настоящее Соглашение, как и Оферта считаются расторгнутыми.
</br></br>
</br></br>
<h2>4. Права интеллектуальной собственности</h2>
4.1. Интеллектуальная собственность Администратора или его клиентов:
</br></br>
4.1.1. Пользователь признает и соглашается с тем, что Интеллектуальная собственность Администратора или его клиентов находится под правовой охраной.
</br></br>
4.1.2. Пользователю предоставляется бесплатная простая (неисключительная) лицензия на использование Сервиса и Программного обеспечения в объеме, необходимом для участия в Опросах.
</br></br>
4.1.3. Пользователю не предоставляются никакие иные права на Интеллектуальную собственность Администратора или его клиентов, за исключением прав, поименованных в предыдущем пункте настоящего Соглашения, в том числе Пользователю запрещается передавать, отчуждать, продавать, копировать, распространять, изменять и иным образом распоряжаться в отношении Интеллектуальной собственности Администратора или его клиентов.
</br></br>
4.2. Интеллектуальная собственность Пользователя:
</br></br>
4.2.1. Размещая в процессе Опроса какие-либо материалы, Пользователь предоставляет Администратору и его клиентам (являющимся организатором и/или заказчиком Опроса) бесплатную простую (неисключительную) лицензию на указанные материалы, без ограничения территории и срока её действия с правом использовать материалы любым незапрещенным законом способом, в том числе посредством публичного воспроизведения, демонстрации, копирования, цифровой обработки, переработки или модификацией, а также с правом на передачу вышеуказанных прав любым третьим лицам (предоставление сублицензий), в том числе трансграничную передачу.
</br></br>
4.2.2. Размещая материалы в Сервисе, Пользователь гарантирует, что:
– является законным правообладателем размещаемых материалов и, публикуя материалы, Пользователь не нарушает прав третьих лиц или условия действующих соглашений;
– Пользователь не связан какими-либо обязательствами в отношении материалов, в том числе, в отношении соблюдения конфиденциальности.
</br></br>
<h2>5. Конфиденциальность и Персональные данные</h2>
5.1. При обработке Персональных данных Респондента Администратор выступает в качестве Оператора.
Оператор обрабатывает Персональные данные Респондента в следующих целях:
— проведения Опросов и организации участия Респондента в Опросах;
— персонализации Сервисов Сайта (настройка Сервисов Сайта под конкретного Респондента), чтобы Оператор мог лучше ориентировать Респондента на конкретные Опросы;
— обеспечения участия Респондента в стимулирующих программах;
— связи с Респондентом, в том числе путём направления уведомлений, запросов и информации, касающихся использования Сервисов Администратора, а также обработки запросов и заявок от Респондента;
— улучшения качества Сервисов Администратора, удобства их использования, разработки дополнительных функциональных возможностей Сервисов, проведения тестирования или испытания сторонних информационных систем;
— проверки предоставленной Респондентом информации;
— выявления и предотвращения нарушений Пользовательского соглашения, Оферты;
— расследования подозрения в мошенничестве или нарушениях прав третьих лиц;
— выполнения обязательств, установленных действующим законодательством, в том числе для ответа на уполномоченные должным образом запросы государственных органов или иных уполномоченных лиц.
</br></br>
5.2. Любая информация о Респонденте обрабатывается при условии его согласия: акцептуя Пользовательское соглашение и Оферту Респондент свободно, своей волей и в своем интересе дает согласие Оператору на обработку своих Персональных данных на условиях, изложенных в согласии на обработку персональных данных.
</br></br>
5.3. Оператор обрабатывает Персональные данные Респондента посредством следующих действий (операций) с Персональными данными, совершаемых с использованием средств автоматизации или без использования таких средств, а именно: сбора, записи, структурирования/ систематизации, накопления, хранения, уточнения (обновления, изменения, модификации), извлечения, использования, раскрытия посредством передачи (распространения, предоставления, доступа), упорядочивания или комбинирования, ограничения, обезличивания, блокирования, удаления, уничтожения Персональных данных (стирания или разрушения), трансграничной передачи.
</br></br>
5.4. Передача Пользователем Конфиденциальной информации третьим лицам, равно как и раскрытие Конфиденциальной информации и любой другой доступ к ней – может быть осуществлен только с письменного разрешения Администратора, или его клиента.
</br></br>
5.5. Пользователь, со своей стороны, примет все меры для сохранения Конфиденциальной информации.
</br></br>
5.6. За разглашение Конфиденциальной информации Пользователь несет ответственность, установленную законодательством Республики Узбекистан.
</br></br>
5.7. Помимо приведенной выше ответственность Пользователь также согласен с тем, что в случае раскрытия Конфиденциальной информации Пользователь лишается доступа к учетной записи в Сервисах Администратора. Данная учетная запись подлежит блокировке.
</br></br>
</br></br>
<h2>6. Прочие условия</h2>
6.1. Настоящее Соглашения вступает в силу с момента прохождения Респондентом регистрации и совершения иных действий, направленных на акцептирование Соглашения.
</br></br>
6.2. Все споры, связанные с неисполнением или ненадлежащим исполнением своих обязательств по настоящему Соглашению, Стороны будут стараться решить путем переговоров.
</br></br>
6.3. В случае недостижения согласия в ходе переговоров, споры будут разрешаться в судебном порядке в соответствии с действующим законодательством РУз.`,
  en: `<h2>Terms and conditions</h2>
  In this Offer, unless the context indicates otherwise, the following terms have the following meanings and are an integral part of it:
“Administrator” is a legal entity, Limited Liability Company "INSIGHT DATA" (TIN 310 439 959), located at the address: Republic of Uzbekistan, Tashkent, Mirzo-Ulugbek district, Olmachi MFY, st. Turtarik house 3-5, created in accordance with the legislation of the Republic of Uzbekistan and carrying out entrepreneurial activities, being the organizer of activities related to conducting surveys of respondents, as well as a developer administering the Telegram bot and other resources developed for the purpose of completing surveys.
“Respondent” is an individual who has reached the age of 18, takes part in a sociological or other survey, questionnaire, and who accepts this Offer by performing the actions specified in the Offer.
Service/Telegram bot/website – @insight_web_bot software/application/site developed and administered by INSIGHT DATA LLC, aimed at providing access to individuals to participate in questionnaires/surveys.
“User Agreement” - in a general sense, an agreement between the Administrator and the User. For the purposes of this offer, the User Agreement is an agreement between the Administrator and the Respondent regarding the terms of use of the Administrator’s Services.
“Survey” is a method of sociological research that consists of collecting and obtaining information about certain opinions, knowledge and social facts that constitute the subject of research.
“Conditional points/Points” is a conditional currency that acts and is credited as a reward to the Respondent for completing a Survey or performing a particular action in the Administrator’s Services, which can be exchanged for Uzbek soum or used in the loyalty programs of the Administrator’s partners.
For the purposes of this offer, terms and definitions in the singular also refer to terms and definitions in the plural and vice versa.
</br></br>
<h2>1. General provisions</h2>
1.1. In accordance with Article 369 of the Civil Code of the Republic of Uzbekistan, this document is a public offer addressed to individuals, and if the conditions set out below are accepted and certain actions are taken, the individual (Respondent) accepts this offer of the Administrator, which is tantamount to concluding an Agreement.
</br></br>
1.2. The Administrator and the Respondent guarantee that they have the necessary legal capacity and capacity, as well as all rights and powers necessary and sufficient to conclude and execute this offer.
</br></br>
1.3. The subject of this offer is the Administrator’s offer of its Services for completing Surveys (Telegram bot, website, mobile application, etc.) to Respondents under the conditions specified in this offer. The Administrator reserves the right to change this Offer at any time without any special notice. If the Respondent continues to use the services of the Administrator after the publication of changes in the offer, it is considered that the Respondent thereby accepts the changes to the terms of the offer.
</br></br>
1.4. With this offer, the Administrator invites an unlimited number of people to take part in online surveys through the Telegram bot @insight_web_bot and other Administrator Services. These Services provide, using the insight.uz web interface and specialized hardware and software, the transmission, accumulation and processing of information entered by Respondents, as well as the results of Surveys in which Respondents periodically take part, presented in electronic form. For participation in Surveys, Respondents are awarded conditional points/points. The Administrator reserves the right, at its sole discretion, to add, cancel or change some or all of the services offered at any time.
</br></br>
1.5. Surveys can be either widespread or narrowly targeted. For each question, a reward is paid in conditional points, which are awarded after completing the entire Survey. When filling out the questionnaire and other activities required during registration, the Administrator has the right to set the cost of each question at his own discretion.
</br></br>
1.5.2. The end of the Survey is the receipt of answers to all questions given in the Survey. If a deadline has been set for the Survey, and this period expires before the Respondent answers all questions, this Survey will be considered invalid and conditional points will not be awarded for it.
</br></br>
1.5.3. Upon achievement of certain indicators (expiration of the validity period, collection of the required data, receipt of answers to all questions posed, etc.), the Survey is considered closed.
</br></br>
1.6. Any of the Respondents has the right, at their discretion, to exchange the conditional points accumulated on their account for cash or other loyalty programs from the Administrator’s partners (Partner promo codes, discounts, etc.), in accordance with clause 1.11. The Respondent can view the number of accumulated points in the corresponding section of the interface, as well as on the website.
</br></br>
1.7. The Respondent is subject to a number of requirements, the full list of which the Respondent can find in the User Agreement and other documents located in the “Rules” section of each software resource. The Administrator has the right to refuse the Respondent to register on the Administrator’s Services in case of non-compliance with the specified requirements, without giving reasons.
</br></br>
1.8. Acceptance of the Offer is recognized as the completion by the Respondent of implicit actions expressed in registering an Account in the Telegram bot and/or on the website (activating/launching the bot) and filling out personal data (providing personal data). The Administrator and the Respondent accept and undertake to comply with both the terms of the Offer and the terms of the User Agreement, and possible additions to these documents. The provisions of the current legislation of the Republic of Uzbekistan apply to the legal relations of the parties not directly regulated by this offer.
</br></br>
By accepting the Offer, the Respondent, among other things, gives the Administrator consent to disseminate the information received from the Respondent in an impersonal form.
</br></br>
If the Respondent disagrees with any of the provisions of this Offer, the Respondent does not have the right to use the Administrator’s Service. If the Respondent continues to use the Administrator’s Services after the publication of changes, it is considered that he thereby accepts the changes to the terms of the Offer in full.
</br></br>
1.9. The registration procedure is given in the User Agreement.
</br></br>
1.10. The Respondent authorizes the Administrator to send requests to various bodies and authorities on his behalf, with the purpose, but not limited to, of verifying the accuracy of the data provided.
</br></br>
1.11. The withdrawal of conditional points into cash or their use in affiliate loyalty programs becomes available when a certain number of conditional points is reached. The minimum amount is 25,000 conditional points. This amount can be changed at any time at the discretion of the Administrator. In order to convert
</br></br>
<h2>2. Rights and obligations</h2>
</br></br>
2.1. The administrator has the right:
</br></br>
2.1.1. provide the Respondent with the opportunity to use the Administrator’s Services to complete Surveys, subject to the Respondent’s compliance with the conditions specified in this Offer and the User Agreement;
</br></br>
2.1.2. at any time, without notice, supplement, edit or otherwise make changes to the terms of this Offer;
</br></br>
2.1.3. at any time, in our sole discretion, add, cancel or change the Services;
</br></br>
2.1.4. invite Respondents to participate in Surveys by sending push notifications or other available means;
</br></br>
2.1.5. not to invite/refuse Respondents to participate in Surveys that do not meet the criteria of the Survey’s target audience or for other reasons, and the Administrator is not obliged to disclose the reasons for such refusals;
</br></br>
2.1.6. unless otherwise directly follows from the terms of this Offer, transfer anonymized information containing the Respondent’s answers to questions within the Surveys to third parties;
</br></br>
2.1.7. block the Respondent's access to the Services, terminate this User Agreement if the Respondent violates the obligations established by the Offer or for other reasons;
</br></br>
2.1.8. make telephone calls to the Respondent at the number specified during registration to clarify any information or report any information;
</br></br>
2.1.9. for technical reasons, suspend the operation of the Services;
</br></br>
2.2. The respondent has the right:
</br></br>
2.2.1. participate in Surveys for free in the manner and under the conditions established by this Offer and the User Agreement;
</br></br>
2.2.2. receive information about the procedure and conditions for participation in Surveys;
</br></br>
2.2.3. refuse to participate in the Survey without giving reasons;
</br></br>
2.2.4. independently make changes and corrections to information about yourself in the Respondent’s Account, provided that such changes and corrections contain current and reliable information;
</br></br>
2.2.5. delete your Account at any time on your own initiative. In this case, the Administrator has the right to continue using personal data.
</br></br>
2.3. The respondent undertakes:
</br></br>
2.3.1. independently familiarize yourself with the terms and conditions of the User Agreement located in the “Rules” section of the Administrator Services;
</br></br>
2.3.2. comply with the terms of the Offer and User Agreement;
</br></br>
2.3.3. provide reliable data requested during Registration in the Services, indicate accurate, complete and consistent information about yourself on all questions asked in the registration form, keep the information provided up to date;
</br></br>
2.3.4. do not re-register in the Services if you have a valid Account;
</br></br>
2.3.5. when completing Surveys, act in good faith, provide informative, meaningful, consistent, consistent, and complete answers to the questions. Answers that are not related to the topic of the Survey, containing profanity, a set of numbers, punctuation marks and letters that have no semantic meaning, contradict each other or common sense, etc., as well as deliberately false answers are considered meaningless;
</br></br>
2.3.6. do not copy or modify the Software; not to create programs derived from the Software; not to penetrate the Software in order to obtain program code;
</br></br>
2.3.7. not to sell, assign, lease, transfer to third parties in any other form of access rights to the Administrator’s Services;

</br></br>
<h2>3. Other conditions</h2>
3.1. This offer comes into force from the moment the Respondent registers and performs other actions aimed at accepting the Offer.
</br></br>
3.2. All disputes related to non-fulfillment or improper fulfillment of their obligations under this Offer, the Parties will try to resolve through negotiations.
</br></br>
3.3. In case of failure to reach agreement during negotiations, disputes will be resolved in court in accordance with the current legislation of the Republic of Uzbekistan.
</br></br>
<h2>Terms of use</h2>
This user agreement governs the relationship regarding the use of the Administrator’s services (Telegram bot @insight_web_bot, website and other services) and the completion of social and other surveys, questionnaires, between the Administrator and an individual (User) who has duly accepted the Offer to complete surveys and registered in accordance with section 1.8. of this user agreement.
</br></br>
<h2>Terms and Definitions</h2>
In this agreement, unless the context otherwise requires, the following terms have the following meanings and are an integral part of it:
“Administrator” is a legal entity, Limited Liability Company "INSIGHT DATA" (TIN 310 439 959), located at: Republic of Uzbekistan, Tashkent, Mirzo-Ulugbek district, Olmachi MFY, st. Turtarik house 3-5, created in accordance with the legislation of the Republic of Uzbekistan and carrying out business activities and is the developer administering the Telegram bot and other resources (services) developed for the purpose of completing surveys and questionnaires, as well as the organizer of these surveys of respondents.
“User” is an individual who receives the right to use the Administrator’s Services, takes part in a sociological or other survey, questionnaire, who accepts the Administrator’s Offer by performing the actions specified in the Offer.
Service/Telegram bot/website – @insight_web_bot software/application/site developed and administered by INSIGHT DATA LLC, aimed at providing access to individuals to participate in questionnaires/surveys.
“Offer” is an offer to an unlimited number of persons to conclude a transaction, outlining the essential conditions. In the context of this agreement, it is an offer to an unlimited number of persons to participate in the Administrator’s promotions (surveys, questionnaires, etc.) on the Administrator’s Services.
“Survey” is a method of sociological research that consists of collecting and obtaining information about certain opinions, knowledge and social facts that constitute the subject of research.
“Conditional points/points” is a conditional currency that acts and is credited as a reward to the User for completing Surveys or performing a particular action in the Administrator’s Services.
Processing of personal data - any action (operation) or set of actions (operations) performed using automation tools or without the use of such means with Personal data, including collection, recording, structuring/systematization, accumulation, storage, clarification (updating, changing, modification ), extraction, use, disclosure, transfer (distribution, otherwise making available, access), arrangement or combination, restriction, depersonalization, blocking, deletion, destruction of Personal Data (erasure or destruction).
For the purposes of this agreement, terms and definitions in the singular also apply to terms and definitions in the plural and vice versa.
</br></br>
<h2>1. General Provisions</h2>
1.1. If the conditions set out below are accepted and certain actions are taken, the individual (User) accepts the terms of the User Agreement and confirms the obligations assumed, which is tantamount to concluding an Agreement.
</br></br>
1.2. The Administrator and the User guarantee that they have the necessary legal capacity and capacity, as well as all rights and powers necessary and sufficient to enter into and execute this agreement.
</br></br>
1.3. The subject of this agreement is the provision by the Administrator of the right to access and use the Services for completing Surveys to Users under the conditions specified in this agreement and the public Offer. The Administrator reserves the right to change this Agreement at any time without any special notice. If the User continues to use the services of the Administrator after the publication of changes to the Agreement, the User is deemed to thereby accept the amended terms.
</br></br>
1.4. By this Agreement, the Administrator grants the User the right to access and use the Services. These Services provide, using a web interface and specialized hardware and software, the transmission, accumulation and processing of information entered by Users, as well as the results of Surveys in which Users periodically take part, presented in electronic form. For participation in Surveys and other activities or actions, Users are awarded conditional points. The Administrator reserves the right, at its sole discretion, to add, cancel or change some or all of the services offered at any time.
</br></br>
1.5. The procedure for exchanging conditional points is given in the Offer, which the User must read when registering in the Administrator’s Services.
</br></br>
1.6. Requirements for the Respondent/User:
</br></br>
1.6.1. The respondent can be any individual residing in the Republic of Uzbekistan, over 18 years of age, who is the holder of an Uzcard bank card issued in his own name and surname.
</br></br>
1.6.2. The respondent can have only 1 (one) Account registered in the Telegram bot and/or on the website, which will be linked to a bank card and personal phone number.
</br></br>
1.6.3. The Respondent is permitted to use only one Account. If this rule is violated, the Administrator reserves the right to block the respondent’s Account without explanation.
</br></br>
1.6.4. Filling out a profile (credentials) for each respondent is mandatory. The Respondent undertakes to provide truthful information about himself and to promptly make changes to the profile and additions if such have occurred in his life or if the profile parameters have been expanded by the creators of the Service (Administrator).
</br></br>
1.6.5. If suspicious Accounts or multiple Accounts are identified, the Administrator has the right to carry out an additional identification procedure, make telephone calls to the User in order to verify or clarify any information specified during registration or specified during Surveys. The administrator also has the right to request a photo (selfie) with a passport/other document confirming the full name, phone number, and the last digits of the bank card.
</br></br>
1.7. The Administrator and the User accept and undertake to comply with both the terms of the User Agreement and the Offer, as well as possible additions to them. The provisions of the current legislation of the Republic of Uzbekistan apply to the legal relations of the parties not directly regulated by this agreement.
</br></br>
By accepting the User Agreement, the User, among other things, gives the Administrator consent to disseminate the information received from the Respondent in an impersonal form.
</br></br>
If the User disagrees with any of the provisions of this Agreement, the User does not have the right to use the Administrator’s Service. If the User continues to use the Administrator’s Services after the publication of changes, it is considered that he thereby accepts the changes to the terms of the Agreement in full.
</br></br>
1.8. Procedure for registration/joining/participation/use of the Administrator Service:
</br></br>
1.8.1. The user launches/joins the bot by following the invitation link, or the link posted in the promotional material. Or the User registers on the Administrator’s website located at: Insight.uz;
</br></br>
1.8.2. The user provides the bot with a mobile phone number, then enters his personal data, full name, date of birth and other requested data in the dialog box. By providing a telephone number, the User automatically confirms his consent that the Administrator will have the right to make telephone calls and send SMS messages to the specified telephone number. The list of provided data is determined at the discretion of the Administrator and can be changed at any time. When withdrawing funds/exchanging points, the User indicates the details of their own bank card.
</br></br>
1.8.3. The provided data is transferred to the Administrator
</br></br>
<h2>2. Rights of the parties</h2>
2.1. The administrator has the right:
</br></br>
2.1.1. provide the User with the opportunity to use the Administrator’s Services, subject to the User’s compliance with the conditions specified in this Agreement and the Offer;
</br></br>
2.1.2. at any time, without notice, supplement, edit or otherwise make changes to the terms of this Agreement;
</br></br>
2.1.3. at any time, in our sole discretion, add, cancel or change the Services;
</br></br>
2.1.4. block the User's access to the Services, terminate this User Agreement if the User violates the obligations established by the Agreement or for other reasons;
</br></br>
2.1.5. for technical reasons, suspend the operation of the Services.
</br></br>
2.2. The administrator undertakes:
</br></br>
2.2.1. process the User's Personal Data, maintain its confidentiality and ensure security in accordance with the terms of this Agreement and the confidentiality provisions.
</br></br>
2.3. The user has the right:
</br></br>
2.3.1. if you meet all the requirements and fulfill the conditions specified in the Agreement, obtain limited access to the Administrator’s Services;
</br></br>
2.3.2. independently make changes and corrections to information about yourself in the User Account, provided that such changes and corrections contain current and reliable information;
</br></br>
2.3.3. delete your Account at any time, on your own initiative.
</br></br>
2.4. The user undertakes:
</br></br>
2.4.1. independently familiarize yourself with all the terms and conditions and comply with these terms of this Agreement and the Offer;
</br></br>
2.4.2. provide reliable data requested during Registration in the Services, indicate accurate, complete and consistent information about yourself on all questions asked in the registration form, keep the information provided up to date;
</br></br>
2.4.3. do not re-register in the Service if you have a valid Account;
</br></br>
2.4.4. do not copy or modify the Software; not to create programs derived from the Software; not to penetrate the Software in order to obtain program code;
</br></br>
2.4.5. not to sell, assign, lease, transfer to third parties in any other form of access rights to the Administrator’s Service.
<h2>3. Access blocking</h2>
3.1. The Administrator blocks access to the Services in the following cases:
</br></br>
3.1.1. deletion by the User of his Account;
</br></br>
3.1.2. withdrawal by the User of consent to the processing of personal data, which is carried out by sending a written application to the Administrator;
</br></br>
3.1.3. changes in the User’s status in terms of compliance with its requirements specified in this present;
</br></br>
3.1.4. identifying the fact of violation by the User of the obligations established in the Agreement and Offer.
</br></br>
3.2. The User's account may be blocked if the User does not use the Service and has not taken part in any Survey conducted through the Service within the previous 6 (six) months.
</br></br>
3.3. The User agrees that the Administrator does not bear any responsibility to him for blocking access to the Service.
</br></br>
3.4. From the date the User’s access to the Service is blocked, this Agreement, as well as the Offer, are considered terminated.
</br></br>
<h2>4. Intellectual Property Rights</h2>
</br></br>
4.1. Intellectual property of the Administrator or its clients:
</br></br>
4.1.1. The User acknowledges and agrees that the Intellectual Property of the Administrator or its clients is under legal protection.
</br></br>
4.1.2. The User is granted a free, simple (non-exclusive) license to use the Service and Software to the extent necessary to participate in Surveys.
</br></br>
4.1.3. The User is not granted any other rights to the Intellectual Property of the Administrator or his clients, with the exception of the rights named in the previous paragraph of this Agreement, including the User is prohibited from transferring, alienating, selling, copying, distributing, modifying and otherwise disposing of the Intellectual Property of the Administrator or his clients.
</br></br>
4.2. User's intellectual property:
</br></br>
4.2.1. By posting any materials during the Survey, the User provides the Administrator and his clients (who are the organizer and/or customer of the Survey) with a free, simple (non-exclusive) license for the specified materials, without limiting the territory and duration of its validity, with the right to use the materials in any way not prohibited by law, including through public reproduction, display, copying, digital processing, processing or modification, as well as the right to transfer the above rights to any third parties (sublicenses), including cross-border transfer.
</br></br>
4.2.2. By posting materials on the Service, the User guarantees that:
- is the legal copyright holder of the posted materials and, by publishing materials, the User does not violate the rights of third parties or the terms of existing agreements;
- The user is not bound by any obligations in relation to the materials, including with regard to confidentiality.
</br></br>
<h2>Privacy and Personal Data</h2>
5.1. When processing the Personal Data of the Respondent, the Administrator acts as an Operator.
The Operator processes the Respondent’s Personal Data for the following purposes:
— conducting Surveys and organizing the Respondent’s participation in Surveys;
— personalization of the Site Services (customizing the Site Services for a specific Respondent) so that the Operator can better target the Respondent to specific Surveys;
— ensuring the Respondent’s participation in incentive programs;
— communication with the Respondent, including by sending notifications, requests and information regarding the use of the Administrator’s Services, as well as processing requests and applications from the Respondent;
— improving the quality of the Administrator’s Services, their ease of use, developing additional functionality of the Services, testing or testing third-party information systems;
— checking the information provided by the Respondent;
— identifying and preventing violations of the User Agreement, Offer;
— investigation of suspicions of fraud or violations of the rights of third parties;
— fulfillment of obligations established by current legislation, including to respond to duly authorized requests from government bodies or other authorized persons.
</br></br>
5.2. Any information about the Respondent is processed subject to his consent: by accepting the User Agreement and the Offer, the Respondent freely, of his own free will and in his own interest, gives consent to the Operator to process his Personal Data on the terms set out in the consent to the processing of personal data.
</br></br>
5.3. The Operator processes the Personal Data of the Respondent through the following actions (operations) with Personal Data, performed using automation tools or without the use of such tools, namely: collection, recording, structuring/systematization, accumulation, storage, clarification (updates, changes, modifications), extraction, use, disclosure by transmission (distribution, provision, access), arrangement or combination, restriction, depersonalization, blocking, deletion, destruction of Personal Data (erasure or destruction), cross-border transfer.
</br></br>
5.4. The transfer of Confidential Information by the User to third parties, as well as the disclosure of Confidential Information and any other access to it, can only be carried out with the written permission of the Administrator or his client.
</br></br>
5.5. The User, for his part, will take all measures to preserve Confidential Information.
</br></br>
5.6. For the disclosure of Confidential Information, the User bears responsibility established by the legislation of the Republic of Uzbekistan.
</br></br>
5.7. In addition to the above responsibilities, the User also agrees that in the event of disclosure of Confidential Information, the User is deprived of access to the account in the Administrator Services. This account is subject to blocking.
</br></br>
<h2>6. Other conditions</h2>
6.1. This Agreement comes into force from the moment the Respondent registers and performs other actions aimed at accepting the Agreement.
</br></br>
6.2. All disputes related to non-fulfillment or improper fulfillment of their obligations under this Agreement, the Parties will try to resolve through negotiations.
</br></br>
6.3. In case of failure to reach agreement during negotiations, disputes will be resolved in court in accordance with the current legislation of the Republic of Uzbekistan.
`,
};
