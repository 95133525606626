interface IProfileData {
  notificationsLabel: string;
  turnOnNotifications: string;
  inviteOthers: string;
  inviteBtn: string;
}
interface IProfileLangData {
  en: IProfileData;
  uz: IProfileData;
  ru: IProfileData;
}

export const profileLangData: IProfileLangData = {
  en: {
    notificationsLabel: "Notifications",
    turnOnNotifications: "Turn on notifications",
    inviteOthers: "Invite Others",
    inviteBtn: "Invite",
  },
  uz: {
    notificationsLabel: "Bildirishnomalar",
    turnOnNotifications: "Bildirishnomalarni yoqish",
    inviteOthers: "Boshqalarini taklif qilish",
    inviteBtn: "Taklif qilish",
  },
  ru: {
    notificationsLabel: "Уведомления",
    turnOnNotifications: "Включить уведомления",
    inviteOthers: "Пригласить других",
    inviteBtn: "Пригласить",
  },
};
