import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { authApi } from "../services/users";
import { ICart } from "../services/users/type";

export interface IProductState {
  // cart: ICart[];
}

const initialState: IProductState = {};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    changeCart: (state, action: PayloadAction<ICart>) => {
      // let arr = state.cart;
      // let index = arr.findIndex((el) => el.product === action.payload.product);
      // if (index !== -1) {
      //   arr[index].quantity = action.payload.quantity;
      // } else {
      //   arr.push(action.payload);
      // }
      // state.cart = arr;
      // localStorage.setItem(CART_ITEMS, JSON.stringify(arr));
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.getProducts.matchFulfilled, (state, action) => {
      // state.products = action.payload.results;
    });
  },
});

export const { changeCart } = productSlice.actions;

export default productSlice.reducer;
