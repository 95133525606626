import { EmptyWalletChange, Home, InfoCircle } from "iconsax-react";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "src/constants/theme";
import "./footer.scss";

export default function Footer() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const items = [
    {
      icon: (
        <Home
          size="20"
          color={colors.black}
          variant={pathname === "/" ? "Bold" : "Linear"}
        />
      ),
      path: "/",
    },
    {
      icon: (
        <EmptyWalletChange
          size="36"
          color={colors.black}
          variant={pathname === "/withdrawal" ? "Bold" : "Linear"}
        />
      ),
      path: "/withdrawal",
    },
    {
      icon: (
        <InfoCircle
          size="20"
          color={colors.black}
          variant={pathname === "/info" ? "Bold" : "Linear"}
        />
      ),
      path: "/info",
    },
  ];

  return (
    <div className="footer">
      <div className="footer-menu">
        {items.map((item, indx) => {
          return (
            <div
              key={indx}
              className="footer-menu-item"
              onClick={() => navigate(item.path)}
            >
              {item.icon}
            </div>
          );
        })}
      </div>
    </div>
  );
}
