import "./styles.scss";
import { Avatar, Button, Switch } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { profileLangData } from "./langData";
import { useTypedSelector } from "src/app/store";
import { useGetProfileMutation } from "src/app/services/profile";
import { IProfileRes } from "src/app/services/profile/type";
import { useEffect, useState } from "react";

function ProfileSectionBar({
  label,
  leftContent,
  rightContent,
}: {
  label: string;
  leftContent: string;
  rightContent: React.ReactNode;
}) {
  return (
    <div style={{ width: "100%" }}>
      <div className="profile-bar-label">{label}</div>
      <div className="profile-section-bar">
        <div className="profile-section-bar-left">{leftContent}</div>
        <div className="profile-section-bar-right">{rightContent}</div>
      </div>
    </div>
  );
}

export default function Profile() {
  const [getProfile, { isLoading }] = useGetProfileMutation();
  const currLang = useTypedSelector((state) => state.language);
  const [profileData, setProfileData] = useState<IProfileRes | null>(null);
  useEffect(() => {
    getProfile()
      .unwrap()
      .then((res) => {
        setProfileData(res);
      });
  }, []);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="profile-container">
      <Avatar size={124} icon={<UserOutlined />} src={profileData?.avatar} />
      <br />
      <h1>
        {profileData?.first_name} {profileData?.last_name}Avazbek Sotvoldiyev
      </h1>
      <h4>{profileData?.balance}200,000 ball</h4>
      <br />
      <ProfileSectionBar
        label={profileLangData[currLang].notificationsLabel}
        leftContent={profileLangData[currLang].turnOnNotifications}
        rightContent={<Switch defaultChecked onChange={() => {}} />}
      />
      <br />
      <ProfileSectionBar
        label=""
        leftContent={profileLangData[currLang].inviteOthers}
        rightContent={
          <Button className="invite-others">
            {profileLangData[currLang].inviteBtn}
          </Button>
        }
      />
    </div>
  );
}
