import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "src/layout";
import Custom404 from "src/pages/404";
import SignUp from "src/pages/login/signUp";
import HomePage from "src/pages/home";
import Quiz from "src/pages/quiz";
import UserProfileProgress from "src/pages/user-progress";
import useRoute from "./useRoute";
import Info from "src/pages/info";
import Profile from "src/pages/profile";
import Verify from "./../pages/login/verify";
import CreatePassword from "src/pages/login/createPassword";
import SignIn from "src/pages/login/signIn";
import { useGetProfileMutation } from "src/app/services/profile";
import { useTypedSelector } from "src/app/store";
import { useNavigate } from "react-router-dom";
import Withdraw from "src/pages/withdrawal";

function RoutElements() {
  const navigate = useNavigate();
  const [getProfile, { isLoading }] = useGetProfileMutation();
  const {
    isAuthenticated,
    profile: { accepted_terms_of_usage },
  } = useTypedSelector((state) => state.auth);
  const location = useLocation();
  const {} = useRoute();
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    getProfile()
      .unwrap()
      .then((res) => {
        if (res.current_question) {
          navigate(`/quiz/${res.current_question.insight}`);
        }
      });
    AOS.init({
      easing: "ease-out-cubic",
      once: true,
      offset: 0,
      duration: 800,
    });
  }, [isAuthenticated]);
  if (isLoading) {
    return <div>Loading</div>;
  } else if (!isAuthenticated) {
    return <div>Not authenticated</div>;
  }
  return (
    <div className="root">
      <Routes>
        {isAuthenticated && accepted_terms_of_usage ? (
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route
              path="/quiz/:id"
              index
              element={<Quiz key={location.pathname} />}
            />
            <Route path="/profile" index element={<Profile />} />
            <Route path="/withdrawal" index element={<Withdraw />} />
            <Route path="/info" index element={<Info />} />
            <Route
              path="/user-progress"
              index
              element={<UserProfileProgress />}
            />
            <Route path="*" element={<Custom404 />} />
          </Route>
        ) : (
          <Route path="/" element={<Layout />}>
            {/* <Route index path="/" element={<SignIn />} />
            <Route index path="/sign-in" element={<SignIn />} />
            <Route index path="/sign-up" element={<SignUp />} />
            <Route index path="/sign-up/verify" element={<Verify />} />
            */}
            <Route path="/" index element={<Info />} />
            {/* <Route
              index
              path="/sign-up/create-password"
              element={<CreatePassword />}
            /> */}
          </Route>
        )}
      </Routes>
    </div>
  );
}

export default RoutElements;
