import "./styles.scss";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function ProfileButton({ size = 54 }) {
  return (
    <Link to={"/profile"}>
      <Avatar size={size} icon={<UserOutlined />} />
    </Link>
  );
}
