import { BrowserRouter as Router } from "react-router-dom";
import "src/styles/global.scss";
import RoutElements from "./routes";
import ReduxProvider from "./provider/redux";
import AntConfigProvider from "./provider/antConfig";

function App() {
  return (
    <ReduxProvider>
      <Router>
        <AntConfigProvider>
          <RoutElements />
        </AntConfigProvider>
      </Router>
    </ReduxProvider>
  );
}

export default App;
