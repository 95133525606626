import { createSlice } from "@reduxjs/toolkit";
import { TOKEN, token } from "src/constants/storage";
import { profileApi } from "../services/profile";
import { authApi } from "../services/auth";
import { IProfileRes } from "../services/profile/type";

interface ITelegramData {
  query_id: string;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
    language_code: string;
    allows_write_to_pm: boolean;
  };
  auth_date: string;
  hash: string;
}

export interface IAuthState {
  token?: string;
  isAuthenticated: boolean;
  profile: IProfileRes;
  telegramData: ITelegramData;
}

const initialState: IAuthState = {
  token: token || "",
  isAuthenticated: false,
  profile: {} as IProfileRes,
  telegramData: {} as ITelegramData,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = "";
      state.isAuthenticated = false;

      localStorage.removeItem(TOKEN);
    },
    changeTelegramData: (state, action) => {
      state.telegramData = action.payload;
      state.isAuthenticated = true;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      profileApi.endpoints.getProfile.matchFulfilled,
      (state, action) => {
        state.profile = action.payload;
      }
    );
    builder.addMatcher(
      authApi.endpoints.createPassword.matchFulfilled,
      (state, action) => {
        state.token = action.payload.token.access;
        state.isAuthenticated = true;
        localStorage.setItem(TOKEN, action.payload.token.access);
      }
    );
    builder.addMatcher(
      authApi.endpoints.signIn.matchFulfilled,
      (state, action) => {
        state.token = action.payload.token.access;
        state.isAuthenticated = true;
        localStorage.setItem(TOKEN, action.payload.token.access);
      }
    );
    builder.addMatcher(
      profileApi.endpoints.acceptTermsOfUsage.matchFulfilled,
      (state, action) => {
        state.profile = action.payload;
      }
    );
  },
});

export const { logout, changeTelegramData } = authSlice.actions;
export default authSlice.reducer;
