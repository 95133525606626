import { Button } from "antd";
import { ArrowLeft } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { colors } from "src/constants/theme";
import "./styles.scss";

export default function ArrowBack({ navigateTo }: { navigateTo?: string }) {
  const navigate = useNavigate();
  return (
    <div className="arrow-back">
      <Button
        type="primary"
        onClick={() => (navigateTo ? navigate(navigateTo) : navigate(-1))}
        icon={<ArrowLeft size="20" color={colors.white} />}
      />
    </div>
  );
}
