import { Outlet } from "react-router-dom";
import Footer from "./footer";
import "./layout.scss";
import { useTypedSelector } from "src/app/store";

const footerExeptions = [
  "/quiz",
  "/sign-in",
  "/sign-up/verify",
  "/sign-up/create-password",
];

function Layout() {
  const currentPath = window.location.pathname;
  if (currentPath.startsWith("/quiz")) {
    footerExeptions.push(currentPath);
  }
  const { profile, isAuthenticated } = useTypedSelector((state) => state.auth);

  return (
    <div className="layout">
      <div className="layout-content">
        <Outlet />
      </div>
      {isAuthenticated &&
        profile.accepted_terms_of_usage &&
        !footerExeptions.includes(currentPath) && <Footer />}
    </div>
  );
}

export default Layout;
