import { api } from "../api";
import { IBaseDataRes } from "../type";
import {
  INextQuestion,
  IOptionRes,
  IQuestionRes,
  IQuizeRes,
  ISubmitQuestion,
} from "./type";

export const insightsApi = api.injectEndpoints({
  endpoints: (build) => ({
    //////// Quize ////////
    //Get quize endpoint
    getQuizes: build.mutation<IBaseDataRes<IQuizeRes>, void>({
      query: () => ({ url: "/insights/" }),
    }),

    getQuizeById: build.mutation<IQuizeRes, number>({
      query: (id) => ({ url: "/insights/" + id }),
    }),
    getQuizQuestion: build.mutation<IBaseDataRes<IQuestionRes>, string>({
      query: (id) => ({ url: "/insights/questions", params: { insight: id } }),
    }),
    submitQuestionResult: build.mutation<INextQuestion, ISubmitQuestion>({
      query: (data) => ({
        url: "/users/submit-question",
        method: "POST",
        body: data,
      }),
    }),

    //////// Option ////////
    //Get option endpoint
    getOption: build.mutation<IBaseDataRes<IOptionRes>, number>({
      query: (id) => ({ url: "/insights/options?question=" + id }),
    }),
  }),
});

export const {
  // Quize
  useGetQuizesMutation,
  useGetQuizeByIdMutation,
  // Question
  useGetQuizQuestionMutation,
  // Option
  useGetOptionMutation,
  // Submit
  useSubmitQuestionResultMutation,
} = insightsApi;
