import { ConfigProvider } from "antd";
import { ProviderProps } from "../type";
import { colors } from "src/constants/theme";

function AntConfigProvider({ children }: ProviderProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.primary,
          colorPrimaryBg: colors.primary,
          // colorBorder: colors.stroke,
          colorText: colors.black,
          // colorTextPlaceholder: colors.text_placeholder,
          fontSize: 14,
          fontFamily: "Poppins",
        },
        components: {
          // Table: {
          //   // colorTextHeading: colors.text_primary_heading,
          //   rowHoverBg: 'disabled',
          //   // borderColor: 'black',
          // },
          // Button: {
          //   paddingContentHorizontal: 30,
          //   // colorBorder: ,
          //   // colorBgTextActive: colors.white,
          //   colorText: colors.white,
          //   // colorTextLightSolid: colors.white,
          // },
          // Input: {
          //   borderRadius: 0,
          //   controlHeight: 40,
          //   paddingContentHorizontal: 20,
          //   fontSize: 16,
          // },
          // Badge: {
          //   colorText: colors.white,
          // },
          // Segmented: {
          //   borderRadius: 8,
          //   controlHeight: 44,
          //   colorText: colors.white,
          //   colorTextLabel: colors.white,
          //   itemSelectedBg: colors.primary,
          //   paddingContentHorizontal: 20,
          // },
          // Tabs: {
          //   fontSize: 16,
          //   inkBarColor: "transparent",
          //   colorBorder: "transparent",
          // },
          // Form: {
          //   labelFontSize: 16,
          //   verticalLabelPadding: 4,
          // },
          // Drawer: {},
          // Checkbox: {
          //   borderRadius: 0,
          // },
          // Dropdown: {
          //   colorText: colors.black,
          // },
          // Breadcrumb: {
          //   itemColor: colors.white,
          //   separatorColor: colors.white,
          //   separatorMargin: 16,
          // },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}

export default AntConfigProvider;
