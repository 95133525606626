import { Flex, Image } from "antd";

interface ImgTxtOptionProps {
  imgsUrl: string[];
  optionTxt: string;
  isActive: boolean;
  handleOnlickFc: () => void;
}

export default function ImgTxtOption({
  imgsUrl,
  optionTxt,
  isActive,
  handleOnlickFc,
}: ImgTxtOptionProps) {
  return (
    <div
      className={`quiz-container-option ${
        isActive && "quiz-container-option-active"
      }`}
      onClick={handleOnlickFc}
    >
      <Flex style={{ width: "100%" }} align="center" gap={10}>
        {imgsUrl.length > 0 &&
          imgsUrl.map((imgUrl) => <Image src={imgUrl} alt={optionTxt} />)}
        <Flex style={{ width: "100%" }} align="center" justify="space-between">
          <h4>{optionTxt}</h4>
        </Flex>
        <div className="circle-radio" />
      </Flex>
    </div>
  );
}
