import { Alert, Col, Row, Spin } from "antd";
import QuizBlock from "./block";
import { useEffect, useState } from "react";
import { IQuizeRes } from "src/app/services/insights/type";
import { useGetQuizesMutation } from "src/app/services/insights";
import { useTypedSelector } from "src/app/store";
import { homeLangData } from "../language/langData";

export default function QuizBlocks() {
  const [data, setData] = useState<IQuizeRes[]>([]); // Update the type of 'data' state variable
  const [getQuizes, { isLoading }] = useGetQuizesMutation();
  const currLang = useTypedSelector((state) => state.language);
  useEffect(() => {
    getQuizes()
      .unwrap()
      .then((res) => {
        setData(res.results);
      });
  }, []);

  if (isLoading)
    return (
      <div style={{ textAlign: "center" }}>
        <Spin />
      </div>
    );
  if (!isLoading && data.length === 0)
    return (
      <Alert
        message={homeLangData[currLang].home.noquiz}
        description={homeLangData[currLang].home.noquizDescription}
        showIcon
        type="warning"
      />
    );

  return (
    <div>
      <div className="home-quizes-title">
        <h1>Quizes</h1>
      </div>
      <br />

      <Row gutter={[16, 16]}>
        {data.map((item, indx) => (
          <Col key={indx} sm={12} xs={12}>
            <QuizBlock
              quizName={item.title[currLang] || ""}
              quizScore={item.ball}
              quizId={item.id}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}
