import { Button, Flex, Input, message } from "antd";
import "./styles.scss";
import ImgTxtQuestion from "./quiz-type-components/img-txt-option";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetQuizQuestionMutation,
  useSubmitQuestionResultMutation,
} from "src/app/services/insights";
import { IQuestionRes } from "src/app/services/insights/type";
import { useTypedSelector } from "src/app/store";
import { submitTxt, textAreaPlaceholder, quizCompleteTxt } from "./langData";
const { TextArea } = Input;

export default function ImgQuiz() {
  const navigate = useNavigate();
  const currLanguege = useTypedSelector((state) => state.language);
  const { id } = useParams<{ id: string }>();
  const [quizQuestion, setQuizQuestion] = useState<IQuestionRes | null>(null);
  const [getQuizQuestions, { isLoading }] = useGetQuizQuestionMutation();
  const [submitQuestionResult, { isLoading: isLoadingSubmit }] =
    useSubmitQuestionResultMutation();
  useEffect(() => {
    if (id) {
      getQuizQuestions(id)
        .unwrap()
        .then((res) => {
          setQuizQuestion(res.results[0]);
        });
    }
  }, []);

  const [activeOptions, setActiveOptions] = useState<number[] | null>(null);
  const [textRes, setTextRes] = useState<string | null>(null);
  const onSubmitResult = () => {
    if (!quizQuestion) return;
    submitQuestionResult(
      quizQuestion?.is_writable
        ? {
            question: quizQuestion.id,
            selected_options: [],
            written_answer: textRes || "",
          }
        : {
            question: quizQuestion.id,
            selected_options: activeOptions || [],
          }
    )
      .unwrap()
      .then((res) => {
        if (!res.next_question) {
          message.success(quizCompleteTxt[currLanguege]);
          navigate("/");
          return;
        }
        setQuizQuestion(res.next_question);
        setActiveOptions(null);
        setTextRes(null);
      });
  };
  if (isLoading) return <div>Loading...</div>;
  return (
    <div className="quiz-container">
      <br />
      <div className="quiz-container-content">
        <div className="quiz-container-title">
          <h1>{quizQuestion?.question[currLanguege]}</h1>
        </div>
        <br />
        {quizQuestion?.image &&
          quizQuestion?.image.length > 0 &&
          quizQuestion?.image.map((imgUrl, imgIndx) => (
            <div key={imgIndx} className="quiz-container-question-image">
              <img src={imgUrl} />
            </div>
          ))}
        <br />
        <br />
        <div className="quiz-container-options">
          <Flex gap={20} vertical>
            {quizQuestion?.is_writable ? (
              <TextArea
                rows={4}
                onChange={(e) => setTextRes(e.target.value)}
                placeholder={textAreaPlaceholder[currLanguege]}
              />
            ) : (
              quizQuestion?.options?.map((option, index) => (
                <ImgTxtQuestion
                  key={index}
                  imgsUrl={option.image}
                  optionTxt={option.option[currLanguege] || ""}
                  isActive={activeOptions?.includes(option.id) || false}
                  handleOnlickFc={() =>
                    quizQuestion.is_multiple_choice
                      ? setActiveOptions((prevv) => {
                          if (prevv?.includes(option.id)) {
                            return prevv?.filter((prev) => prev !== option.id);
                          }
                          return [...(prevv || []), option.id];
                        })
                      : setActiveOptions([option.id])
                  }
                />
              ))
            )}
          </Flex>
        </div>
      </div>
      <div className="submit-result">
        <Button type="primary" onClick={onSubmitResult}>
          {submitTxt[currLanguege]}
        </Button>
      </div>
    </div>
  );
}
