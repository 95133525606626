export const colors = {
  bg_primary: "#e9eeea",
  yellow: "#EDFF8C",
  //
  primary: "#000",
  text_placeholder: "#BDBFC6",
  stroke: "#E4E6F2",
  white: "#FFF",
  black: "#000",
  black_01: "#666",
  light_gray: "#e9e9e9",
  link: "#2875e5",
  blue: "#154EA0",
  dark_bg: "#4A4A4A",
  bg_grey2: "#F1F2F8",
};
