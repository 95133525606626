import { Dropdown, MenuProps } from "antd";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import { ArrowDown2 } from "iconsax-react";
import { changeLanguage } from "src/app/slices/languageSlice";
import { useAppDispatch, useTypedSelector } from "src/app/store";

const LanguageDropdown = () => {
  const currentLang = useTypedSelector((state) => state.language);
  const dispatch = useAppDispatch();
  const items: MenuItemType[] = [
    {
      key: "uz",
      label: "O'zbekcha",
      onClick: () => {
        dispatch(changeLanguage("uz"));
      },
    },
    {
      key: "ru",
      label: "Русский",
      onClick: () => {
        dispatch(changeLanguage("ru"));
      },
    },
    {
      key: "en",
      label: "English",
      onClick: () => {
        dispatch(changeLanguage("en"));
      },
    },
  ];

  return (
    <Dropdown trigger={["click"]} menu={{ items }} placement="bottom">
      <a onClick={(e) => e.preventDefault()}>
        <div className="selectbtn">
          <p>{currentLang}</p>
          <ArrowDown2 size="16" />
        </div>
      </a>
    </Dropdown>
  );
};

export default LanguageDropdown;
