import { Flex, Badge } from "antd";
import { useNavigate } from "react-router-dom";

export default function QuizBlock({
  quizName,
  quizScore,
  quizId,
}: {
  quizName: string;
  quizScore: number;
  quizId: number;
}) {
  const navigate = useNavigate();
  return (
    <Badge.Ribbon text="new" color="lime">
      <Flex
        onClick={() => {
          navigate("/quiz/" + quizId);
        }}
        className="quiz-block"
        justify="space-between"
        vertical
      >
        <h3>{quizName}</h3>
        <br />
        <p>
          Score: <b>{quizScore}</b>
        </p>
      </Flex>
    </Badge.Ribbon>
  );
}
