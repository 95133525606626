import { QuestionOutlined, RightOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import { useNavigate } from "react-router-dom";

export default function QuizBar({ quizName, navigateTo }: { quizName: string; navigateTo: string }) {
  const navigate = useNavigate();

  return (
    <div className="quiz-bar" onClick={() => navigate(navigateTo)}>
      <Flex style={{ width: "100%" }} align="center" gap={10}>
        <QuestionOutlined className="question-icon" />
        <Flex style={{ width: "100%" }} align="center" justify="space-between">
          <h4>{quizName}</h4>
          <div>
            <RightOutlined style={{ fontSize: "12px" }} />
          </div>
        </Flex>
      </Flex>
    </div>
  );
}
