import { Flex } from "antd";
import { useTypedSelector } from "src/app/store";
import ProfileButton from "src/components/common/profile-button";
import HomeInviteCard from "./components/invite";
import LanguageDropdown from "./components/language";
import { homeLangData } from "./components/language/langData";
import HomeProgressCard from "./components/progress";
import HomeScoreCard from "./components/score";
import "./styles.scss";
import QuizBlocks from "./components/quizBlocks";
import { useEffect } from "react";
import { useGetProfileMutation } from "src/app/services/profile";

export default function HomePage() {
  const currLang = useTypedSelector((state) => state.language);
  const [getProfile, { isLoading }] = useGetProfileMutation();

  const {
    profile: { first_name, last_name },
  } = useTypedSelector((state) => state.auth);
  useEffect(() => {
    getProfile().unwrap();
  }, []);
  return (
    <div className="home">
      <div className="home-header">
        <div className="home-header-logo">Insight</div>
        <div className="home-header-right">
          <LanguageDropdown />
          <ProfileButton size={32} />
        </div>
      </div>
      <div className="home-content container">
        <div className="home-greeting">
          {homeLangData[currLang].home.greeting} {first_name} {last_name}
        </div>

        <Flex gap="middle" vertical>
          <HomeInviteCard txt={homeLangData[currLang].home.share} />
          <HomeScoreCard />
          {/* <HomeProgressCard /> */}
          <QuizBlocks />
        </Flex>
      </div>
    </div>
  );
}
