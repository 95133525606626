import { afertaLangData } from "./aferta";

interface ICommonInfoData {
  infoHeader: string;
  infoText: string;
}

interface ICommonInfoLayoutData {
  slides: ICommonInfoData[];
  aferta: string;
  afertaTitle: string;
  afertaLowerTitle: string;
  aftertaConfirmation: string;
  confirm: string;
}

interface ICommonInfoLangData {
  en: ICommonInfoLayoutData;
  uz: ICommonInfoLayoutData;
  ru: ICommonInfoLayoutData;
}

export const commonInfoLangData: ICommonInfoLangData = {
  en: {
    slides: [
      {
        infoHeader: "Insight",
        infoText: "How does Insight work?",
      },
      {
        infoHeader: "Register",
        infoText: "Register to get started",
      },
      {
        infoHeader: "Another insight",
        infoText: "Another insight text",
      },
    ],
    aferta: afertaLangData.en,
    afertaLowerTitle: "Terms of use",
    afertaTitle: "Rules",
    aftertaConfirmation: "I agree to the terms of use",
    confirm: "Confirm",
  },
  uz: {
    afertaTitle: "Qoidalar",
    afertaLowerTitle: "Oferta",
    slides: [
      {
        infoHeader: "Insight",
        infoText: "Insight qanday ishlaydi?",
      },
      {
        infoHeader: "Ro'yxatdan o'tish",
        infoText: "Boshlash uchun ro'yxatdan o'ting",
      },
      {
        infoHeader: "Boshqa insight",
        infoText: "Boshqa insight matni",
      },
    ],
    aferta: afertaLangData.uz,
    aftertaConfirmation: "Men foydalanish shartlariga roziman",
    confirm: "Tasdiqlash",
  },
  ru: {
    slides: [
      {
        infoHeader: "Insight",
        infoText: "Как работает Insight?",
      },
      {
        infoHeader: "Регистрация",
        infoText: "Зарегистрируйтесь, чтобы начать",
      },
      {
        infoHeader: "Другой взгляд",
        infoText: "Другой текст",
      },
    ],
    aferta: afertaLangData.ru,
    afertaLowerTitle: "Оферта",
    afertaTitle: "Правила",
    aftertaConfirmation: "Я согласен/согласна с условиями использования",
    confirm: "Подтвердить",
  },
};
