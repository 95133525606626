import { red } from "@ant-design/colors";
import { ShareAltOutlined } from "@ant-design/icons";
import { Button } from "antd";

const HomeInviteCard = ({ txt }: { txt: string }) => {
  return (
    <div className="home-invite">
      <p>{txt}</p>
      <Button icon={<ShareAltOutlined style={{ color: red[0], fontSize: "1.5rem" }} />}></Button>
    </div>
  );
};

export default HomeInviteCard;
