interface IWithdrawalData {
  emptyErrorMsg: string;
}

interface IWithdrawalAllLang {
  en: IWithdrawalData;
  ru: IWithdrawalData;
  uz: IWithdrawalData;
}

export const withdrawalLangData: IWithdrawalAllLang = {
  en: {
    emptyErrorMsg: "Oops, nothing here yet",
  },
  ru: {
    emptyErrorMsg: "Упс, пока что тут ничего нет",
  },
  uz: {
    emptyErrorMsg: "Voy, bu yerda hozircha hech narsa yo‘q",
  },
};
