import { useTypedSelector } from "src/app/store";
import { withdrawalLangData } from "./langData";
import "./styles.scss";
import { Button } from "antd";
import { Link } from "react-router-dom";
export default function Withdraw() {
  const currLang = useTypedSelector((state) => state.language);
  return (
    <div className="withdrawal">
      <h1>{withdrawalLangData[currLang].emptyErrorMsg}</h1>
      <br />
      <Link to="/">
        <Button className="withdrawal-back-btn" type="primary">
          Orqaga
        </Button>
      </Link>
    </div>
  );
}
