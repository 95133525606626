// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
// Import Swiper styles
import { Navigation } from "swiper/modules";
import { commonInfoLangData } from "./lang";
import { useTypedSelector } from "src/app/store";
import { Button, Checkbox } from "antd";
import { useState } from "react";
import { useAcceptTermsOfUsageMutation } from "src/app/services/profile";

function InfoSlidePage({ children }: { children: React.ReactNode }) {
  return <div className="slide-container">{children}</div>;
}

export default function Info() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const currLang = useTypedSelector((state) => state.language);
  const [acceptTerms, { isLoading }] = useAcceptTermsOfUsageMutation();
  const {
    profile: { accepted_terms_of_usage },
  } = useTypedSelector((state) => state.auth);
  return (
    <div className="info-page">
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        {commonInfoLangData[currLang]?.slides.map((info, index) => (
          <SwiperSlide key={index}>
            <InfoSlidePage>
              <div className="title">{info.infoHeader}</div>
              <div className="subtitle">{info.infoText}</div>
            </InfoSlidePage>
          </SwiperSlide>
        ))}
        <SwiperSlide>
          <InfoSlidePage>
            <h1 className="aferta-header">
              {commonInfoLangData[currLang]?.afertaTitle}
            </h1>
            <h2>{commonInfoLangData[currLang]?.afertaLowerTitle}</h2>
            <div className="aferta-content-container">
              <div
                className="aferta-content"
                dangerouslySetInnerHTML={{
                  __html: commonInfoLangData[currLang]?.aferta,
                }}
              />
              {!accepted_terms_of_usage && (
                <div className="aferta-confirmation">
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                  >
                    {commonInfoLangData[currLang]?.aftertaConfirmation}
                  </Checkbox>
                  <Button
                    loading={isLoading}
                    disabled={!checked}
                    type="primary"
                    onClick={() => {
                      alert("acceptTerms");
                      acceptTerms()
                        .unwrap()
                        .then(() => {
                          navigate("/");
                        })
                        .catch((e) => {
                          alert(JSON.stringify(e));
                        });
                    }}
                  >
                    {commonInfoLangData[currLang]?.confirm}
                  </Button>
                </div>
              )}
            </div>
          </InfoSlidePage>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
