interface IUserProgressData {
  all: string;
  unFinished: string;
  New: string;
  dailyProgress: string;
  noQuiz: string;
}
interface IUserProgressLangData {
  en: IUserProgressData;
  uz: IUserProgressData;
  ru: IUserProgressData;
}

export const userProgressLangData: IUserProgressLangData = {
  en: {
    all: "All",
    unFinished: "Unfinished",
    New: "New",
    dailyProgress: "Quizes Progress",
    noQuiz: "No quiz available",
  },
  uz: {
    all: "Hammasi",
    unFinished: "Tugatilmagan",
    New: "Yangi ",
    dailyProgress: "So'rovnomalar Jarayoni",
    noQuiz: "Sizda hech qanday testlar mavjud emas",
  },
  ru: {
    all: "Все",
    unFinished: "Незаконченный",
    New: "Новый",
    dailyProgress: "Прогресс викторин",
    noQuiz: "Нет доступных тестов",
  },
};
