import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useVerifyTokenMutation } from "src/app/services/auth";
import { changeTelegramData, logout } from "src/app/slices/authSlice";
import { changeLaunchType, changeState } from "src/app/slices/layout";
import { useAppDispatch, useTypedSelector } from "src/app/store";

export default function useRoute() {
  const navigate = useNavigate();
  const [verifyToken] = useVerifyTokenMutation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { token } = useTypedSelector((state) => state.auth);
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      const userData = window.Telegram.WebApp.initDataUnsafe.user;
      const telegramData = window.Telegram.WebApp.initDataUnsafe;
      if (!userData) {
        dispatch(changeLaunchType("default"));
        return;
      } else {
        dispatch(
          changeState({
            launchType: "telegram",
            telegramDetails: userData,
          })
        );
        dispatch(changeTelegramData(telegramData));
        // if (token) {
        //   verifyToken({ token })
        //     .unwrap()
        //     .then()
        //     .catch(() => {
        //       dispatch(logout());
        //     });
        // } else {
        //   navigate("/sign-in");
        // }
      }
    }
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  return {};
}
