import { api } from '../api';
import { IBaseDataRes } from '../type';
import {
  IOrder,
  IOrderRes,
  IProduct,
  ITaxRate,
  IValidate,
  IValidateRes,
} from './type';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    // Get products endpoint
    getProducts: build.mutation<IBaseDataRes<IProduct>, void>({
      query: () => ({ url: `/products` }),
    }),
    //Get product detail endpoint
    getProductId: build.query<IProduct, number>({
      query: (id) => ({ url: `/products/${id}` }),
      // providesTags: (_post, _err, id) => [{ type: "Products", id }],
    }),
    //Get tax-rates endpoint
    getTaxRates: build.mutation<IBaseDataRes<ITaxRate>, void>({
      query: () => ({ url: `/tax-rates` }),
    }),
    //Get tax-rates endpoint
    getTaxRateDetail: build.mutation<
      IBaseDataRes<ITaxRate>,
      { state: string; zip_code: string }
    >({
      query: (params) => ({ url: `/tax-rates`, params }),
    }),
    //Validation endpoint
    validateEmail: build.mutation<IValidateRes, IValidate>({
      query: (body) => ({
        url: '/validate-email',
        method: 'POST',
        body,
      }),
    }),
    //Validation endpoint
    sendOrders: build.mutation<IOrderRes, IOrder>({
      query: (body) => ({
        url: '/orders',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetProductsMutation,
  useGetProductIdQuery,
  useGetTaxRatesMutation,
  useGetTaxRateDetailMutation,
  useValidateEmailMutation,
  useSendOrdersMutation,
} = authApi;
