export const textAreaPlaceholder = {
  en: "Write your answer here",
  ru: "Напишите ваш ответ здесь",
  uz: "Javoringizni shu yerga yozing",
};

export const submitTxt = {
  en: "Submit",
  ru: "Отправить",
  uz: "Yuborish",
};

export const quizCompleteTxt = {
  en: "Quiz completed successfully!",
  ru: "Викторина успешно завершена!",
  uz: "Savol-javob muvaffaqiyatli tugallandi!",
};
