import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ILayout = {
  launchType: "telegram" | "default";
  telegramDetails: {
    first_name: string;
    last_name: string;
    username?: string;
    photo_url?: string;
    id: number;
  };
};
const initialState: ILayout = {
  launchType: "default",
  telegramDetails: {
    first_name: "",
    last_name: "",
    id: 2,
  },
}; // Explicitly stating that initialState is of type ILayout

const layoutSlice = createSlice({
  name: "languageInterface",
  initialState: initialState as ILayout, // Ensuring the type is explicitly ILayout
  reducers: {
    changeLaunchType: (
      state,
      action: PayloadAction<ILayout["launchType"]>
    ) => ({
      ...state,
      launchType: action.payload,
    }), // Correctly handle the state change
    changeState: (_state, action: PayloadAction<ILayout>) => action.payload,
  },
});

export default layoutSlice.reducer;
export const { changeLaunchType, changeState } = layoutSlice.actions;
