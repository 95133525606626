import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { message } from "antd";
import { logout } from "src/app/slices/authSlice";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses createAsyncThunk from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      // const error_message =
      //   action.payload?.data?.message ??
      //   action.payload?.data?.msg ??
      //   action.payload?.data?.err?.message ??
      //   "";
      // error_message &&
      //   error_message !== "A validation error occurred." &&
      //   message.warning(error_message);
      const errors = action.payload?.data?.errors ?? "";

      if (errors.length > 0) {
        errors.forEach((item: any) => {
          item && message.warning(String(item?.detail));
        });
      }
      const status = action.payload?.status;
      if (status === 500) {
        message.warning(
          "Server bilan bog'liq xatolik. Iltimos bu haqda ma'sul xodimlarga xabar bering"
        );
      } else if (status === 401 || status === 403) {
        console.log("403 err");

        // window.location.href = "/sign-in";
        api.dispatch(logout());
        message.warning("Iltimos avval tizimga kiring!");
      }
    }

    return next(action);
  };
