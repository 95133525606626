interface ILangData {
  home: {
    greeting: string;
    share: string;
    yourProgress: string;
    progressDescription: string;
    updated: string;
    yourTotalScore: string;
    noquiz: string;
    noquizDescription: string;
  };
}
const en: ILangData = {
  home: {
    greeting: "Hello ",
    share: "Share this app with others",
    yourProgress: "Your Progress",
    progressDescription: "You can see your progress bar here",
    updated: "updated 4 hours ago",
    yourTotalScore: "Your Total Score",
    noquiz: "No new quizes found",
    noquizDescription: "Please check back later for new quizes.",
  },
};
const uz: ILangData = {
  home: {
    greeting: "Salom ",
    share: "Ushbu ilovani boshqalar bilan ulashing",
    yourProgress: "Sizning Progressingiz",
    progressDescription: "Siz bu yerda o'z progressingizni ko'rishingiz mumkin",
    updated: "4 soat avval yangilandi",
    yourTotalScore: "Jami ballaringiz",
    noquiz: "Yangi testlar topilmadi",
    noquizDescription: "Keyinroq yangi testlar uchun qayta tekshiring.",
  },
};
const ru: ILangData = {
  home: {
    greeting: "Привет ",
    share: "Поделитесь этим приложением с другими",
    yourProgress: "Ваш прогресс",
    progressDescription: "Вы можете увидеть свой прогресс-бар здесь",
    updated: "обновлено 4 часа назад",
    yourTotalScore: "Ваш общий счет",
    noquiz: "Новые тесты не найдены",
    noquizDescription: "Пожалуйста, вернитесь позже за новыми тестами.",
  },
};

interface IHomeLangData {
  en: ILangData;
  uz: ILangData;
  ru: ILangData;
}

export const homeLangData: IHomeLangData = { en, uz, ru };
