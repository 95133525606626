import { useTypedSelector } from "src/app/store";
import { homeLangData } from "../language/langData";

export default function HomeScoreCard() {
  const currLang = useTypedSelector((state) => state.language);
  const { profile } = useTypedSelector((state) => state.auth);

  return (
    <div className="home-score">
      <h3>{homeLangData[currLang].home.yourTotalScore}</h3>
      <h4 className="home-score-balance">{profile.balance}</h4>
    </div>
  );
}
